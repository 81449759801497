'use client';
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { Search, User, X, Check, Edit, CreditCard } from 'lucide-react';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { axiosAuth } from "../utils/axios";
import Layout from "../layout";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";

// Interfaces
interface RootState {
  main: {
    user: {
      id: string;
      boxId: string;
      branchId: string;
      companyId: string;
    };
  };
}

interface Product {
  id: string;
  code: string;
  name: string;
  price: number;
  iva: string;
}

interface SaleItem extends Product {
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

interface Customer {
  id: string;
  name: string;
  ruc: string;
}

interface Account {
  id: number;
  name: string;
  number: string;
  bank: {
    name: string;
  };
}

interface PaymentMethod {
  type: 'efectivo' | 'cheque' | 'transferencia';
  amount: number;
  accountId?: number;
  bank?: string;
  accountNumber?: string;
}

interface Quote {
  number: string;
  expirationDate: string;
  amount: number;
}

const SalesSimulator = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.main.user);
  const location = useLocation();
  const { state } = location;
  const { page } = useParams();

  // Basic states
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [number, setNumber] = useState<number>();

  // User-related states
  const [sellerId] = useState(user.id);
  const [boxId] = useState(user.boxId);
  const [currencyId] = useState(1);
  const [branchId] = useState(user.branchId);
  const [depositId] = useState(1);
  const [companyId] = useState(user.companyId);

  // Product-related states
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedSaleItem, setSelectedSaleItem] = useState<SaleItem | null>(null);
  const [saleItems, setSaleItems] = useState<SaleItem[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);

  // Customer-related states
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [clients, setClients] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  // Payment-related states
  const [banks, setBanks] = useState([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [paymentType, setPaymentType] = useState<string>("contado");
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [currentPaymentType, setCurrentPaymentType] = useState<'efectivo' | 'cheque' | 'transferencia'>('efectivo');
  const [currentPaymentAmount, setCurrentPaymentAmount] = useState(0);
  const [selectedBank, setSelectedBank] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  // Quote-related states
  const [quoteFrequency, setQuoteFrequency] = useState(30);
  const [quotesQuantity, setQuotesQuantity] = useState(3);
  const [calculatedQuotes, setCalculatedQuotes] = useState<Quote[]>([]);
  const [isQuotesComplete, setIsQuotesComplete] = useState(false);

  // Dialog states
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [isQuantityDialogOpen, setIsQuantityDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isQuotesDialogOpen, setIsQuotesDialogOpen] = useState(false);

  // Calculated values
  const total = saleItems.reduce((sum, item) => sum + item.totalPrice, 0);
  const formattedTotal = new Intl.NumberFormat('es-PY', {
    style: 'decimal',
    maximumFractionDigits: 0
  }).format(total);

  const totalPaid = paymentMethods.reduce((sum, method) => {
    const amount = Number(method.amount);
    return sum + (isNaN(amount) ? 0 : amount);
  }, 0);

  const remainingAmount = total - totalPaid;

  // Effects
  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      if (searchTerm) {
        try {
          await handleSearchProducts(searchTerm);
        } catch (error) {
          if (isSubscribed) {
            setError(error instanceof Error ? error.message : 'Error en la búsqueda');
          }
        }
      } else {
        setFilteredProducts([]);
      }
    };

    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchCustomers = async () => {
      if (customerSearchTerm) {
        try {
          await handleSearchCustomers(customerSearchTerm);
        } catch (error) {
          if (isSubscribed) {
            setError(error instanceof Error ? error.message : 'Error en la búsqueda de clientes');
          }
        }
      } else {
        setClients([]);
      }
    };

    fetchCustomers();
    return () => {
      isSubscribed = false;
    };
  }, [customerSearchTerm]);

  useEffect(() => {
    fetchSales();
  }, []);

  // API Functions
  const fetchSales = async () => {
    const pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    try {
      const salesRequest = await axiosAuth.get(
        `/salesup?page=${pageQuery}&companyId=${user.companyId}&branchId=${user.branchId}&limit=1`
      );

      if (salesRequest.data.error) {
        if (salesRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
          return;
        }
        throw new Error(salesRequest.data.message);
      }

      setClients(salesRequest.data.clients.rows);
      setBanks(salesRequest.data.banks);
      setAccounts(salesRequest.data.accounts);

      const currentBox = salesRequest.data.boxes[0].number;
      const generalNumber = localStorage.getItem("generalNumber");

      if (generalNumber === null) {
        setNumber(currentBox + 1);
        localStorage.setItem("generalNumber", String(currentBox + 1));
      } else {
        setNumber(currentBox + 1);
      }

      // Handle quote data if present in state
      if (state) {
        // Find client in existing clients or fetch if not found
        const existingClient = salesRequest.data.clients.rows.find(
          (client: { id: any; }) => client.id === state.clientId
        );

        if (existingClient) {
          setSelectedCustomer(existingClient);
        } else {
          const clientResponse = await axiosAuth.get(
            `/client?id=${state.clientId}`
          );
          setSelectedCustomer(clientResponse.data.client);
          setClients(prev => [...prev, clientResponse.data.client]);
        }

        // Process items from quote
        const newSaleItems: SaleItem[] = [];

        for (const quoteItem of state.itemsToCheckIn) {
          let product: Product;

          // Check if product exists in filtered products or fetch it
          const existingProduct = filteredProducts.find(p => p.id === quoteItem.id);

          if (existingProduct) {
            product = existingProduct;
          } else {
            const productResponse = await axiosAuth.get(`/item?id=${quoteItem.id}`);
            product = productResponse.data.item;
            setFilteredProducts(prev => [...prev, product]);
          }

          // Create sale item from quote item
          const saleItem: SaleItem = {
            id: product.id,
            code: product.code,
            name: product.name,
            price: product.price,
            iva: product.iva,
            quantity: quoteItem.qty,
            unitPrice: quoteItem.closedPrice,
            totalPrice: quoteItem.closedPrice * quoteItem.qty
          };

          newSaleItems.push(saleItem);
        }

        // Update sale items
        setSaleItems(newSaleItems);

        // If quote includes payment type or other relevant data, set those too
        if (state.paymentType) {
          setPaymentType(state.paymentType);
        }
      }

    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error al cargar datos');
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearchProducts = async (search: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosAuth.get(`/searchItems?search=${search}&page=1&boole=false`);
      if (response.data?.items?.rows) {
        setFilteredProducts(response.data.items.rows);
      } else {
        throw new Error('Formato de respuesta inválido');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : "Error al cargar los productos");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSearchCustomers = async (search: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosAuth.get(`/searchClients?search=${search}`);
      if (response.data?.clients?.rows) {
        setClients(response.data.clients.rows);
      } else {
        throw new Error('Formato de respuesta inválido');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : "Error al cargar los clientes");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Handler Functions
  const handleProductSelect = (product: Product) => {
    setSelectedProduct(product);
    setUnitPrice(product.price);
    setIsQuantityDialogOpen(true);
    setIsProductDialogOpen(false);
    setSearchTerm('');
  };

  const addOrUpdateProduct = () => {
    if (!selectedProduct) return;

    const newItem: SaleItem = {
      ...selectedProduct,
      quantity,
      unitPrice,
      totalPrice: quantity * unitPrice
    };

    if (selectedSaleItem) {
      setSaleItems(items =>
        items.map(item =>
          item.id === selectedSaleItem.id ? newItem : item
        )
      );
    } else {
      setSaleItems(items => [...items, newItem]);
    }

    setIsQuantityDialogOpen(false);
    setSelectedProduct(null);
    setSelectedSaleItem(null);
    setQuantity(1);
    setUnitPrice(0);
  };

  const handleEditSaleItem = (item: SaleItem) => {
    setSelectedSaleItem(item);
    setSelectedProduct(item);
    setQuantity(item.quantity);
    setUnitPrice(item.unitPrice);
    setIsQuantityDialogOpen(true);
  };

  const removeItem = (id: string) => {
    setSaleItems(items => items.filter(item => item.id !== id));
  };

  const handleAddPaymentMethod = () => {
    if (!currentPaymentAmount || currentPaymentAmount <= 0) {
      alert('El monto debe ser mayor a 0');
      return;
    }

    const newTotal = totalPaid + currentPaymentAmount;
    if (newTotal > total) {
      alert(`El monto máximo permitido es ${(total - totalPaid).toFixed(2)}`);
      return;
    }

    if (currentPaymentType === 'transferencia' && !selectedBank) {
      alert('Por favor seleccione una cuenta bancaria para la transferencia');
      return;
    }

    const newPaymentMethod: PaymentMethod = {
      type: currentPaymentType,
      amount: currentPaymentAmount,
    };

    if (currentPaymentType === 'transferencia') {
      const selectedAccount = accounts.find(acc => acc.id.toString() === selectedBank);
      if (selectedAccount) {
        newPaymentMethod.accountId = selectedAccount.id;
        newPaymentMethod.bank = selectedAccount.bank.name;
        newPaymentMethod.accountNumber = selectedAccount.number;
      }
    }

    setPaymentMethods(methods => [...methods, newPaymentMethod]);
    setCurrentPaymentAmount(0);
    setSelectedBank('');
  };

  const removePaymentMethod = (index: number) => {
    setPaymentMethods(methods => methods.filter((_, i) => i !== index));
  };

  const calculateQuotes = () => {
    const baseAmount = Math.round(total / quotesQuantity);
    const quotes: Quote[] = Array.from(
      { length: quotesQuantity },
      (_, index) => ({
        number: (index + 1).toString(),
        expirationDate: new Date(
          new Date().setDate(new Date().getDate() + quoteFrequency * (index + 1))
        ).toISOString().split('T')[0],
        amount: baseAmount,
      })
    );

    setCalculatedQuotes(quotes);
    setIsQuotesComplete(true);
  };

  const handleCompleteSale = async () => {
    if (!selectedCustomer) {
      alert('Debe seleccionar un cliente.');
      return;
    }

    if (saleItems.length === 0) {
      alert('Debe agregar al menos un producto.');
      return;
    }

    if (paymentType === 'credito' && !isQuotesComplete) {
      setIsQuotesDialogOpen(true);
      return;
    }

    if (paymentType === 'contado' && totalPaid < total) {
      setIsPaymentDialogOpen(true);
      return;
    }

    if (isSaving) return;
    setIsSaving(true);

    const generalNumber = localStorage.getItem("generalNumber") ?? "1";

    const saleData = {
      clientId: selectedCustomer.id,
      sellerId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      expeditionPoint: "001-001",
      stamped: "16938716",
      number: generalNumber,
      saleconditionId: paymentType === 'credito' ? 2 : 1,
      deleted: 0,
      salesdetail: saleItems.map(item => ({
        id: item.id,
        qty: item.quantity,
        price: item.unitPrice,
        subtotal: item.totalPrice,
        iva: item.iva,
      })),
      date: new Date().toISOString().split('T')[0],
      totalAmount: total,
      payments: paymentMethods.map(method => ({
        type: method.type,
        amount: method.amount,
        accountId: method.accountId,
      })),
      dues: paymentType === 'credito' ? calculatedQuotes : [],
    };

    try {
      const response = await axiosAuth.post('/sales', saleData);

      if (response.data.error) {
        throw new Error('Error al crear la venta');
      }

      localStorage.setItem("generalNumber", (parseInt(generalNumber) + 1).toString());
      // Reset all states
      setSaleItems([]);
      setSelectedCustomer(null);
      setPaymentMethods([]);
      setPaymentType("contado");
      setIsQuotesComplete(false);
      setCalculatedQuotes([]);
      setCurrentPaymentAmount(0);
      setCurrentPaymentType('efectivo');
      setSelectedBank('');
      setAccountNumber('');
      setQuoteFrequency(30);
      setQuotesQuantity(3);
      setCustomerSearchTerm('');
      setSearchTerm('');
      setIsSuccessDialogOpen(true);

    } catch (error) {
      alert('Ocurrió un error al intentar crear la venta.');
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Layout>
      <div className="max-w-full mx-auto bg-white h-screen flex flex-col">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />

        {/* Header - Fixed */}
        <div className="p-4 space-y-2 bg-white">
          <div className="flex items-center justify-between text-base">
            <div className="flex items-center gap-2">
              {paymentType === 'contado' && (
                <Dialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen}>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle className="text-2xl">Métodos de Pago</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                      <div className="flex justify-between text-xl">
                        <span>Total a pagar:</span>
                        <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(total)}</span>
                      </div>
                      <div className="flex justify-between text-xl text-green-600">
                        <span>Total pagado:</span>
                        <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(totalPaid)}</span>
                      </div>
                      <div className="flex justify-between text-xl text-red-600">
                        <span>Restante:</span>
                        <span>{new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(remainingAmount)}</span>
                      </div>

                      <div className="space-y-2">
                        <h3 className="text-xl font-medium">Pagos registrados:</h3>
                        {paymentMethods.map((method, index) => (
                          <div key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded">
                            <div className="flex flex-col">
                              <div className="flex items-center gap-2">
                                <span className="capitalize">{method.type}</span>
                                <span>
                                  {new Intl.NumberFormat('es-PY', { style: 'decimal', maximumFractionDigits: 0 }).format(method.amount)}
                                </span>
                              </div>
                              {method.type === 'transferencia' && method.accountId && (
                                <div className="text-sm text-gray-600">
                                  {accounts.find(acc => acc.id === method.accountId)?.name}
                                </div>
                              )}
                            </div>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => removePaymentMethod(index)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <X className="h-5 w-5" />
                            </Button>
                          </div>
                        ))}
                      </div>

                      <div className="space-y-4">
                        <Select value={currentPaymentType} onValueChange={(value: 'efectivo' | 'cheque' | 'transferencia') => setCurrentPaymentType(value)}>
                          <SelectTrigger className="w-full text-xl">
                            <SelectValue placeholder="Método de pago" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="efectivo" className="!text-xl">Efectivo</SelectItem>
                            <SelectItem value="cheque" className="!text-xl">Cheque</SelectItem>
                            <SelectItem value="transferencia" className="!text-xl">Transferencia</SelectItem>
                          </SelectContent>
                        </Select>

                        {currentPaymentType === 'transferencia' && (
                          <div className="space-y-2">
                            <label className="text-xl font-medium">Cuenta Bancaria</label>
                            <Select value={selectedBank} onValueChange={setSelectedBank}>
                              <SelectTrigger className="w-full text-xl">
                                <SelectValue placeholder="Seleccione la cuenta" />
                              </SelectTrigger>
                              <SelectContent>
                                {accounts.map((account) => (
                                  <SelectItem key={account.id} value={account.id.toString()} className="!text-xl">
                                    {account.name} - {account.number}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        )}

                        <div className="space-y-2">
                          <label className="text-xl font-medium">Monto</label>
                          <Input
                            type="number"
                            value={currentPaymentAmount}
                            onChange={(e) => setCurrentPaymentAmount(Number(e.target.value))}
                            className="text-xl"
                          />
                        </div>

                        <Button
                          onClick={handleAddPaymentMethod}
                          className="w-full text-xl"
                          disabled={currentPaymentAmount <= 0 || totalPaid + currentPaymentAmount > total}
                        >
                          Agregar Pago
                        </Button>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button
                        variant="ghost"
                        onClick={() => setIsPaymentDialogOpen(false)}
                        className="text-xl"
                      >
                        Cancelar
                      </Button>
                      <Button
                        onClick={() => {
                          if (remainingAmount === 0) {
                            setIsPaymentDialogOpen(false);
                            handleCompleteSale();
                          } else {
                            alert('Debe cubrir el monto total de la venta');
                          }
                        }}
                        className="text-xl"
                        disabled={remainingAmount > 0}
                      >
                        Finalizar Venta
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
              <Dialog open={isCustomerDialogOpen} onOpenChange={setIsCustomerDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <User className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Seleccionar Cliente</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar clientes..."
                      value={customerSearchTerm}
                      onChange={(e) => setCustomerSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center text-xl">Cargando...</div>}
                    {error && <div className="text-center text-xl text-red-500">{error}</div>}
                    {clients.map(customer => (
                      <div
                        key={customer.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setIsCustomerDialogOpen(false);
                          setCustomerSearchTerm('');
                        }}
                      >
                        <div className="text-xl">{customer.name}</div>
                        <div className="text-base text-gray-500">RUC: {customer.ruc}</div>

                      </div>
                    ))}
                   
                  </div>
                  <DialogFooter>
                    <Button variant="ghost" onClick={() => setIsCustomerDialogOpen(false)} className="text-xl">
                      Cerrar
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              <Dialog open={isProductDialogOpen} onOpenChange={setIsProductDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Search className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Buscar Producto</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar productos..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center text-xl">Cargando...</div>}
                    {error && <div className="text-center text-xl text-red-500">{error}</div>}
                    {filteredProducts.map(product => (
                      <div
                        key={product.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleProductSelect(product)}
                      >
                        <div>
                          <div className="text-xl">{product.name}</div>
                          <div className="text-base text-gray-500">{product.code}</div>
                        </div>
                        <span className="text-xl">
                          {new Intl.NumberFormat('es-PY', {
                            style: 'decimal',
                            maximumFractionDigits: 0
                          }).format(product.price)}
                        </span>
                      </div>
                    ))}
                  </div>
                  <DialogFooter>
                    <Button variant="ghost" onClick={() => setIsProductDialogOpen(false)} className="text-xl">
                      Cerrar
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
             
            </div>
            <h4 className="font-bold text-xl">001-001-{String(number).padStart(7, '0')}</h4> 
            <Select
              value={paymentType}
              onValueChange={(value) => {
                setPaymentType(value);
                /*  if (value === 'credito') {
                   setIsQuotesDialogOpen(true);
                 } */
              }}
            >
              <SelectTrigger className="w-[140px] text-2xl">
                <SelectValue placeholder="Tipo de pago" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="contado" className="!text-2xl">Contado</SelectItem>
                <SelectItem value="credito" className="!text-2xl">Crédito</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        

        {/* Cliente seleccionado - Fixed */}
        {selectedCustomer && (
          <div className="px-4 py-2 mx-3 bg-gray-100 rounded-md">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-medium text-xl">{selectedCustomer.name}</div>
                <div className="text-base text-gray-500">RUC: {selectedCustomer.ruc}</div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedCustomer(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
          </div>
        )}
      
        

        {/* Lista de items - Scrollable */}
        <div className="flex-1 p-4 overflow-y-auto">
          {saleItems.map((item, index) => (
            <div key={item.id} className="flex items-center justify-between py-2">
              <div className="flex items-start gap-2">
                <span className="bg-gray-100 text-gray-600 w-6 h-6 flex items-center justify-center rounded text-xl">
                  {index + 1}
                </span>
                <div>
                  <div className="font-medium">{item.name}</div>
                  <div className="text-base text-gray-500">
                    {item.code} - {item.quantity}x @ {new Intl.NumberFormat('es-PY', {
                      style: 'decimal',
                      maximumFractionDigits: 0
                    }).format(item.unitPrice)}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="!text-xl">
                  {new Intl.NumberFormat('es-PY', {
                    style: 'decimal',
                    maximumFractionDigits: 0
                  }).format(item.totalPrice)}
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleEditSaleItem(item)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit className="h-5 w-5" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => removeItem(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="fixed bottom-0 left-0 w-full p-4 bg-white border-t border-gray-200">
          <div className="flex justify-between items-center mb-2">
            <span className="!text-1xl text-gray-600 mx-4">IVA:</span>
            <span className="!text-2xl text-gray-600 mx-4">
              {new Intl.NumberFormat('es-PY', {
                style: 'decimal',
                maximumFractionDigits: 0
              }).format(Math.round(total / 11))}
            </span>
          </div>
          <Button
            variant="primary"
            size="lg"
            className={`w-full bg-[#111e40] text-[#ffffff] py-8 px-4 rounded-full text-3xl font-bold shadow-lg hover:shadow-2xl transition duration-300 ease-in-out ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handleCompleteSale}
            disabled={isSaving}
          >
            PYG {formattedTotal}
          </Button>
        </div>

        {/* Quantity Dialog */}
        <Dialog open={isQuantityDialogOpen} onOpenChange={setIsQuantityDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-2xl">
                {selectedSaleItem ? 'Editar Producto' : 'Detalles del Producto'}
              </DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="space-y-2">
                <label className="text-xl font-medium">
                  {selectedProduct?.name}
                </label>
                <div className="text-base text-gray-500">
                  {selectedProduct?.code}
                </div>
              </div>
              <div className="grid gap-2">
                <label className="text-xl font-medium">
                  Cantidad
                </label>
                <Input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                  min="1"
                  className="text-xl"
                />
              </div>
              <div className="grid gap-2">
                <label className="text-xl font-medium">
                  Precio Unitario
                </label>
                <Input
                  type="number"
                  value={unitPrice}
                  onChange={(e) => setUnitPrice(Math.max(0, parseInt(e.target.value) || 0))}
                  min="0"
                  className="text-xl"
                />
              </div>
              <div className="flex justify-between font-medium text-xl">
                <span>Total:</span>
                <span>
                  {new Intl.NumberFormat('es-PY', {
                    style: 'decimal',
                    maximumFractionDigits: 0
                  }).format(quantity * unitPrice)}
                </span>
              </div>
            </div>
            <DialogFooter>
              <Button variant="ghost" onClick={() => setIsQuantityDialogOpen(false)} className="text-xl">
                Cancelar
              </Button>
              <Button onClick={addOrUpdateProduct} className="text-xl">
                {selectedSaleItem ? 'Actualizar' : 'Agregar'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={isSuccessDialogOpen} onOpenChange={setIsSuccessDialogOpen}>
          <DialogContent className="flex items-center justify-center h-screen bg-[#111e40]">
            <div className="text-center text-white">
              <Check className="mx-auto mb-4 w-16 h-16 text-[#ffffff]" />
              <h2 className="text-2xl text-[#ffffff] font-bold mb-6">¡Venta Guardada!</h2>
              <div className="flex justify-center gap-4">
                <Button variant="outline" className="bg-white text-[#111e40]" onClick={() => setIsSuccessDialogOpen(false)}>
                  Atrás
                </Button>
                <Button className="bg-white text-[#111e40]">Imprimir</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={isQuotesDialogOpen} onOpenChange={setIsQuotesDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-xl">Configuración de Cuotas</DialogTitle>
            </DialogHeader>
            <div className="space-y-6 text-lg">
              <div>
                <label className="block font-semibold mb-2">Frecuencia entre cuotas (días)</label>
                <Input
                  type="number"
                  value={quoteFrequency}
                  onChange={(e) => setQuoteFrequency(Number(e.target.value))}
                  className="w-full p-2 text-right border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block font-semibold mb-2">Cantidad de cuotas</label>
                <Input
                  type="number"
                  value={quotesQuantity}
                  onChange={(e) => setQuotesQuantity(Number(e.target.value))}
                  className="w-full p-2 text-right border border-gray-300 rounded-md"
                />
              </div>
              <Button onClick={calculateQuotes} className="w-full text-lg">Calcular Cuotas</Button>

              {calculatedQuotes.length > 0 && (
                <div>
                  <h3 className="font-bold text-lg mb-4">Cuotas Calculadas:</h3>
                  <table className="min-w-full border-collapse border border-gray-300 text-lg">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2 text-right">#</th>
                        <th className="border border-gray-300 px-4 py-2 text-right">Monto</th>
                        <th className="border border-gray-300 px-4 py-2 text-right">Fecha</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calculatedQuotes.map((quote, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="border border-gray-300 px-4 py-2 text-right">{index + 1}</td>
                          <td className="border border-gray-300 px-4 py-2 text-right">
                            {new Intl.NumberFormat('es-PY', {
                              style: 'decimal',
                              maximumFractionDigits: 0,
                            }).format(quote.amount)}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 text-right">
                            <input
                              type="date"
                              value={quote.expirationDate}
                              onChange={(e) =>
                                setCalculatedQuotes((prev) =>
                                  prev.map((q, i) =>
                                    i === index ? { ...q, expirationDate: e.target.value } : q
                                  )
                                )
                              }
                              className="w-full p-2 border border-gray-300 rounded-md text-right"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>


      </div>
    </Layout>

  )
}
export default SalesSimulator;
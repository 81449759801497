import React, { useState, Fragment, useEffect,useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import plusimg from "../assets/img/plus.png";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import dataOriginImg from "../assets/img/dataorigin.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import plus from "../assets/img/plus.png";

const DataOrigins = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [dataOrigins, setDataOrigins] = useState([]);
  const [companies, setCompanies] = useState([]);
  
  const [companyId, setCompanyId] = useState(user.companyId);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [dataOriginId, setDataOriginId] = useState(0);
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const inputRef = useRef(null);

  async function fetchDataOrigins() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let dataOrigins = await axiosAuth.get("/dataorigins?page=" + pageQuery);

    if (dataOrigins.data.error) {
      if (dataOrigins.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

  

    setIsFetching(false);

    setDataOrigins(dataOrigins.data.dataorigins.rows);
    setTotal(dataOrigins.data.dataorigins.count);
    setPages(dataOrigins.data.dataorigins.pages);
    if (dataOrigins.data.companies?.rows) {
      setCompanies(dataOrigins.data.companies.rows);
    } else {
      console.error("No se encontraron datos de compañías");
    }


    console.log(dataOrigins.data);
    
  }

  useEffect(() => {
    fetchDataOrigins();
  }, []);

  useEffect(() => {
    fetchDataOrigins();
  }, [location]);

  const editDataOrigin = (dataOriginId) => {
    setAction("edit");
    setDataOriginId(dataOriginId);

    let dataOrigin = dataOrigins.filter(
      (dataOrigin) => dataOrigin.id === dataOriginId
    )[0];

    setDescription(dataOrigin.description);
    setCompanyId(dataOrigin.companyId);
    setSearch(dataOrigin.company.name);
    setOpen(true);
  };

  const clearFields = () => {
    setDataOriginId(0);
    setDescription("");
    setSearch("");
  };

  const handleSubmit = async () => {
    if (description === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = dataOriginId;
      let newDataOriginsObj = [];

      let updateDataOriginRequest = await axiosAuth.put("/dataorigins", {
        id,
        description,
        companyId,
      });

      if (updateDataOriginRequest.data.error) {
        if (updateDataOriginRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      dataOrigins.map((dataOrigin) => {
        if (dataOrigin.id == id) {
          newDataOriginsObj.push(updateDataOriginRequest.data);
        } else {
          newDataOriginsObj.push(dataOrigin);
        }
        return "";
      });

      setDataOrigins(newDataOriginsObj);
    } else {
      let createDataOriginRequest = await axiosAuth.post("/dataorigins", {
        description,
        companyId,
      });

      if (createDataOriginRequest.data.error) {
        if (createDataOriginRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newDataOrigins = [...dataOrigins];
      newDataOrigins.push(createDataOriginRequest.data);
      setDataOrigins(newDataOrigins);
    }
    clearFields();
    setOpen(false);
  };

  const deleteDataOrigin = async (dataOriginId) => {
    if (window.confirm("Desea eliminar este origen de datos ?")) {
      let deleteDataOriginRequest = await axiosAuth.delete("/dataOrigins", {
        params: { id: dataOriginId },
      });

      if (deleteDataOriginRequest.data.error) {
        if (deleteDataOriginRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newDataOriginsObj = [];

      dataOrigins.map((dataOrigin) => {
        if (dataOrigin.id !== dataOriginId) {
          newDataOriginsObj.push(dataOrigin);
        } else {
          if (user.roleId == 1) {
            dataOrigin.deleted = 1;
            newDataOriginsObj.push(dataOrigin);
          }
        }
        return "";
      });

      setDataOrigins(newDataOriginsObj);
    }
  };
  // Filtrar las empresas según el texto de búsqueda
  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (id, name) => {
    setCompanyId(id);
    setSearch(name); // Mostrar el nombre seleccionado en el input
    setShowDropdown(false); // Cerrar el dropdown
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
            initialFocus={inputRef}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} origen de
                            datos
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="BrandId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="BrandId"
                                      type="text"
                                      value={dataOriginId}
                                    />
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <input
                                     ref={inputRef} 
                                      type="text"
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      placeholder="Buscar empresa..."
                                      value={search}
                                      onChange={(e) => setSearch(e.target.value)}
                                      onFocus={() => setShowDropdown(true)} // Abrir el dropdown al enfocar
                                    />
                                    {showDropdown && (
                                      <ul className="absolute z-10 w-full bg-white border rounded shadow-md max-h-40 overflow-y-auto">
                                        {filteredCompanies.length > 0 ? (
                                          filteredCompanies.map((company) => (
                                            <li
                                              key={company.id}
                                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer capitalize"
                                              onClick={() => handleSelect(company.id, company.name)}
                                            >
                                              {company.name}
                                            </li>
                                          ))
                                        ) : (
                                          <li className="py-2 px-3 text-gray-500">No se encontraron empresas</li>
                                        )}
                                      </ul>
                                      )}
                                   {/*  <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="companyId"
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option>Seleccione una empresa</option>
                                      {companies?.length > 0 &&
                                        companies.map((company) => (
                                          <option value={company?.id}>
                                            {company?.name}
                                          </option>
                                        ))}
                                    </select> */}
                                  </div>
                                )}
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Descripcion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Origenes de datos
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] sm:bottom-[10px] bottom-[-5px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img alt="" src={plus} className="w-8" />
          CREAR ORIGEN
        </button>

        <div className="w-[95%] p-6 mx-auto overflow-auto sm:max-h-[70vh] max-h-[70vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="uppercase !text-white">Id</th>
                {user.roleId == 1 && <th className="uppercase !text-white text-left">Empresa</th>}
                <th className="uppercase !text-white text-left">Descripcion</th>
                <th className="uppercase !text-white">Fecha Alta</th>
                <th className="uppercase !text-white">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {dataOrigins.length > 0 &&
                !isFetching &&
                dataOrigins?.map((dataOrigin) => {
                  const empresa = companies.find((comp) => comp.id === dataOrigin.companyId);
                  return (
                    <tr key={dataOrigin.id}>
                      <td>{dataOrigin.id}</td>
                      {user.roleId == 1 && <td className="text-left">{empresa?.name  || "Sin Empresa"}</td>}
                      <td className="text-left">{dataOrigin.description}</td>
                      <td>
                        {dataOrigin.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editDataOrigin(dataOrigin.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>

                      </td>
                    </tr>
                     
                  );
                
                })
              
                }
              {dataOrigins.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="origenesdedatos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default DataOrigins;

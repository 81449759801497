import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import DateComponent from "../components/date";
import PaymentAlert from "../components/paymentAlert";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import logo from "../assets/img/logo-gray.png";
import { useDispatch, useSelector } from "react-redux";


charts(FusionCharts);
const Dashboard = () => {
  const dispatch = useDispatch();
  const [isAccountBlocked, setIsAccountBlocked] = useState(true);
  const user = useSelector((state) => state.main.user);
  const [companyId, setCompanyId] = useState(user.companyId);
  console.log(companyId);

  const dataSource = {
    chart: {
      palettecolors: "5876A3,2B4A78,1C314F",
      toolTipBorderColor: "transparent",
      toolTipBgColor: "#000",
      toolTipColor: "#ccc",
      showToolTipShadow: "1",
      toolTipBgAlpha: "50",
      labelFontColor: "#ccc",
      xAxisNameFontColor: "#ccc",
      yAxisNameFontColor: "#ccc",
      canvasbgColor: "#00002d",
      showCanvasBorder: "0",
      bgColor: "#00002d",
      bgAlpha: "10",
      captionFontColor: "#ccc",
      subcaptionFontColor: "#ccc",
      caption: "Recommended Portfolio Split",
      subcaption: "For a net-worth of $1M",
      showvalues: "1",
      showBorder: "0",
      showPlotBorder: "0",
      showpercentintooltip: "1",
      numberprefix: "$",
      enablemultislicing: "1",
      theme: "umber",
      xAxisName: "Day",
      yAxisName: "No. of Visitors",
    },
    data: [
      {
        label: "Equity",
        value: "300000",
      },
      {
        label: "Debt",
        value: "230000",
      },
      {
        label: "Bullion",
        value: "180000",
      },
      {
        label: "Real-estate",
        value: "270000",
      },
      {
        label: "Insurance",
        value: "20000",
      },
    ],
  };

  return (
    <>
      <Layout>
        <h1 className="ml-5 mt-5 text-3xl title secondary-color font-family-thin">
          Dashboard
        </h1>
       {/*  {[ 13].includes(companyId) && <PaymentAlert />} */}
        <DateComponent className="sm:block hidden" />
        {/* 
        <div className="container-95 p-5 mx-auto overflow-auto dashboard-container">
          <img src={logo} style={{ width: "300px" }} className="m-auto mt-24" />
          {/* <ReactFusioncharts
            type="pie2d"
            width="100%"
            dataFormat="JSON"
            dataSource={dataSource}
          />

          <ReactFusioncharts
            type="column2d"
            width="100%"
            dataFormat="JSON"
            dataSource={dataSource}
          />

          <ReactFusioncharts
            type="bar2d"
            width="100%"
            dataFormat="JSON"
            dataSource={dataSource}
          />

          <ReactFusioncharts
            type="area2d"
            width="100%"
            dataFormat="JSON"
            dataSource={dataSource}
          /> 
        </div> */}
      </Layout>
    </>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Search, User, X, Edit } from 'lucide-react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { axiosAuth } from "../utils/axios";
import Layout from "../layout";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";

interface RootState {
  main: {
    user: {
      id: string;
      branchId: string;
      companyId: string;
    };
  };
}

interface Product {
  id: string;
  code: string;
  name: string;
  price: number;
  iva: string;
}

interface QuoteItem extends Product {
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  observation: string;
}

interface Customer {
  id: string;
  name: string;
  ruc: string;
}

const QuotationSystem = () => {
  const user = useSelector((state: RootState) => state.main.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedQuoteItem, setSelectedQuoteItem] = useState<QuoteItem | null>(null);
  const [quoteItems, setQuoteItems] = useState<QuoteItem[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [observation, setObservation] = useState("");
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [clients, setClients] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [isQuantityDialogOpen, setIsQuantityDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const total = quoteItems.reduce((sum, item) => sum + item.totalPrice, 0);

  useEffect(() => {
    if (searchTerm) {
      handleSearchProducts(searchTerm);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (customerSearchTerm) {
      handleSearchCustomers(customerSearchTerm);
    } else {
      setClients([]);
    }
  }, [customerSearchTerm]);

  const handleSearchProducts = async (search: string) => {
    setLoading(true);
    try {
      const response = await axiosAuth.get(`/searchItems?search=${search}&page=1&boole=true`);
      setFilteredProducts(response.data.items.rows);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Error al cargar los productos");
    } finally {
      setLoading(false);
    }
  };
  const createMarkup = (html: string) => {
    return { __html: html };
  };

  const handleSearchCustomers = async (search: string) => {
    setLoading(true);
    try {
      const response = await axiosAuth.get(`/searchClients?search=${search}`);
      setClients(response.data.clients.rows);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Error al cargar los clientes");
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (product: Product) => {
    setSelectedProduct(product);
    setUnitPrice(product.price);
    setIsQuantityDialogOpen(true);
    setIsProductDialogOpen(false);
    setSearchTerm('');
  };

  const addOrUpdateProduct = () => {
    if (!selectedProduct) return;

    const newItem: QuoteItem = {
      ...selectedProduct,
      quantity,
      unitPrice,
      totalPrice: quantity * unitPrice,
      observation: observation || ''
    };

    if (selectedQuoteItem) {
      setQuoteItems(items =>
        items.map(item =>
          item.id === selectedQuoteItem.id ? newItem : item
        )
      );
    } else {
      setQuoteItems(items => [...items, newItem]);
    }

    setIsQuantityDialogOpen(false);
    setSelectedProduct(null);
    setSelectedQuoteItem(null);
    setQuantity(1);
    setUnitPrice(0);
    setObservation('');
  };


  const handleEditQuoteItem = (item: QuoteItem) => {
    setSelectedQuoteItem(item);
    setSelectedProduct(item);
    setQuantity(item.quantity);
    setUnitPrice(item.unitPrice);
    setObservation(item.observation);
    setIsQuantityDialogOpen(true);
  };

  const removeItem = (id: string) => {
    setQuoteItems(items => items.filter(item => item.id !== id));
  };

  const handleSaveQuotation = async () => {
    if (!selectedCustomer) {
      alert('Debe seleccionar un cliente.');
      return;
    }

    if (quoteItems.length === 0) {
      alert('Debe agregar al menos un producto.');
      return;
    }

    if (isSaving) return;
    setIsSaving(true);

    const quotationData = {
      clientId: selectedCustomer.id,
      sellerId: user.id,
      currencyId: 1,
      branchId: user.branchId,
      companyId: user.companyId,
      pricesdetail: quoteItems.map(item => ({
        id: item.id,
        qty: item.quantity,
        name: item.name,
        observation: item.observation,
        price: item.unitPrice.toString(),
        subtotal: item.totalPrice.toString(),
        iva: item.iva,
        cost: "0",
        utilityPrice: "0",
        utilityPrice2: "0",
        priceAmount: item.totalPrice.toString()
      })),
      totalAmount: total,
      deleted: 0
    };

    try {
      const response = await axiosAuth.post('/prices', quotationData);
      if (response.data.error) {
        throw new Error('Error al crear la cotización');
      }
      
      setQuoteItems([]);
      setSelectedCustomer(null);
      alert('Cotización guardada exitosamente');
    } catch (error) {
      alert('Ocurrió un error al intentar crear la cotización.');
    } finally {
      setIsSaving(false);
    }
  }
 
  return (
    <Layout>
      <div className="max-w-full mx-auto bg-white h-screen flex flex-col">
        <div className="p-4 space-y-2 bg-white">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Dialog open={isCustomerDialogOpen} onOpenChange={setIsCustomerDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <User className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Seleccionar Cliente</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar clientes..."
                      value={customerSearchTerm}
                      onChange={(e) => setCustomerSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center">Cargando...</div>}
                    {error && <div className="text-center text-red-500">{error}</div>}
                    {clients.map(customer => (
                      <div
                        key={customer.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setIsCustomerDialogOpen(false);
                          setCustomerSearchTerm('');
                        }}
                      >
                        <div className="text-xl">{customer.name}</div>
                        <div className="text-gray-500">RUC: {customer.ruc}</div>
                      </div>
                    ))}
                  </div>
                </DialogContent>
              </Dialog>
  
              <Dialog open={isProductDialogOpen} onOpenChange={setIsProductDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Search className="h-8 w-8" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="text-2xl">Buscar Producto</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <Input
                      type="text"
                      placeholder="Buscar productos..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-4 text-xl"
                    />
                    {loading && <div className="text-center">Cargando...</div>}
                    {error && <div className="text-center text-red-500">{error}</div>}
                    {filteredProducts.map(product => (
                      <div
                        key={product.id}
                        className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleProductSelect(product)}
                      >
                        <div>
                          <div className="text-xl">{product.name}</div>
                          <div className="text-gray-500">{product.code}</div>
                        </div>
                        <span className="text-xl">
                          {new Intl.NumberFormat('es-PY', {
                            style: 'decimal',
                            maximumFractionDigits: 0
                          }).format(product.price)}
                        </span>
                      </div>
                    ))}
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <h4 className="font-bold text-xl">Cotización</h4>
          </div>
        </div>
  
        {selectedCustomer && (
          <div className="px-4 py-2 mx-3 bg-gray-100 rounded-md">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-medium text-xl">{selectedCustomer.name}</div>
                <div className="text-gray-500">RUC: {selectedCustomer.ruc}</div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedCustomer(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
          </div>
        )}
  
        <div className="flex-1 p-4 overflow-y-auto">
          {quoteItems.map((item, index) => (
            <div key={item.id} className="flex items-center justify-between py-2">
              <div className="flex items-start gap-2">
                <span className="bg-gray-100 text-gray-600 w-6 h-6 flex items-center justify-center rounded text-xl">
                  {index + 1}
                </span>
                <div>
                  <div className="font-medium">{item.name}</div>
                  <div className="text-gray-500">
                    {item.code} - {item.quantity}x @ {new Intl.NumberFormat('es-PY', {
                      style: 'decimal',
                      maximumFractionDigits: 0
                    }).format(item.unitPrice)}
                  </div>
                  {item.observation && (
                  <div 
                    className="text-sm text-gray-500"
                    dangerouslySetInnerHTML={createMarkup(item.observation)}
                  />
                )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-xl">
                  {new Intl.NumberFormat('es-PY', {
                    style: 'decimal',
                    maximumFractionDigits: 0
                  }).format(item.totalPrice)}
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleEditQuoteItem(item)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit className="h-5 w-5" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => removeItem(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>
          ))}
        </div>
  
        <div className="fixed bottom-0 left-0 w-full p-4 bg-white border-t border-gray-200">
          <Button
             variant="primary"
             size="lg"
             className={`w-full bg-[#111e40] text-[#ffffff] py-8 px-4 rounded-full text-3xl font-bold shadow-lg hover:shadow-2xl transition duration-300 ease-in-out ${isSaving ? 'opacity-50 cursor-not-allowed' : ''
               }`}
               onClick={handleSaveQuotation}
            disabled={isSaving}
          >
            {new Intl.NumberFormat('es-PY', {
              style: 'decimal',
              maximumFractionDigits: 0
            }).format(total)}
          </Button>
        </div>
  
        <Dialog open={isQuantityDialogOpen} onOpenChange={setIsQuantityDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              {selectedQuoteItem ? 'Editar Producto' : 'Detalles del Producto'}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="space-y-2">
              <label className="text-xl font-medium">
                {selectedProduct?.name}
              </label>
              <div className="text-gray-500">
                {selectedProduct?.code}
              </div>
            </div>
            <div className="grid gap-2">
              <label className="text-xl font-medium">Cantidad</label>
              <Input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                min="1"
                className="text-xl"
              />
            </div>
            <div className="grid gap-2">
              <label className="text-xl font-medium">Precio Unitario</label>
              <Input
                type="number"
                value={unitPrice}
                onChange={(e) => setUnitPrice(Math.max(0, parseInt(e.target.value) || 0))}
                min="0"
                className="text-xl"
              />
            </div>
            <div className="grid gap-2">
              <label className="text-xl font-medium">Observación</label>
              <div className="min-h-[200px]">
                <ReactQuill
                  theme="snow"
                  value={observation}
                  onChange={setObservation}
                  className="h-[150px]"
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['clean']
                    ]
                  }}
                />
              </div>
            </div>
            <div className="flex text-white justify-between font-medium text-xl mt-8">
             
              <span className="!text-white">
                {new Intl.NumberFormat('es-PY', {
                  style: 'decimal',
                  maximumFractionDigits: 0
                }).format(quantity * unitPrice)}
              </span>
            </div>
          </div>
          <DialogFooter>
            <Button variant="ghost" onClick={() => setIsQuantityDialogOpen(false)} className="text-xl">
              Cancelar
            </Button>
            <Button onClick={addOrUpdateProduct} className="text-xl">
              {selectedQuoteItem ? 'Actualizar' : 'Agregar'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      </div>
    </Layout>
   )
  }
  export default QuotationSystem;
import { Fragment, useEffect, useState, useRef } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { endpoint } from "../utils/constants";

const ComboBox = (props) => {
  var { data, setData, value, index, items, placeholder, dataToDisplay } = props;

  const [selected, setSelected] = useState(
    data != undefined && data.length > 0
      ? data.filter((data) => data.id == value)[0]
      : null
  );
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelected(
      data != undefined && data.length > 0
        ? data.filter((data) => data.id == value)[0]
        : null
    );
  }, [value, data]);

  useEffect(() => {
    setTimeout(function () {
      if (document.getElementById("toFocus") != null) {
        document.getElementById("toFocus").focus();
      }
    }, 100);
  }, []);

  const numberWithCommas = (x) => {
    return x
      .toString()
      .replaceAll(",", "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const filteredData =
    query === ""
      ? data
      : data.filter((data) => {
        if (data?.name === undefined) {
          data.name = "";
        }
        if (data?.fullname === undefined) {
          data.fullname = "";
        }
        if (data?.description === undefined) {
          data.description = "";
        }
   
        if (data?.ruc === undefined) {
          data.ruc = "";
        }
        if (data?.barcode === undefined) {
          data.barcode = "";
        }
        if (data?.fantasyname === undefined) {
          data.fantasyname = "";
        }
        const normalizedQuery = query.toLowerCase().replace(/\s+/g, "");
        return (
          data.name.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery) ||
          data.fullname.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery) ||
          data.description.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery) ||
         
          data.ruc.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery) ||
          data.barcode.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery) ||
          (data.fantasyname &&
            data.fantasyname.toLowerCase().replace(/\s+/g, "").includes(normalizedQuery))
        );
      });

  return (
    <Combobox
      value={selected}
      disabled={props.disabled ? true : false}
      onChange={(e) => {
        setSelected(e);
        if (index != undefined) {
          let newItems = [...items];
          newItems.map((item, i) => {
            if (index == i) {
              item.id = e.id;
              item.name = e.name;
              item.descriptionf= e.descriptionf;
              item.price = numberWithCommas(e.price);
              item.cost = numberWithCommas(e.costFob);
              item.utilityPrice = numberWithCommas(e.price2);
              item.utilityPrice2 = numberWithCommas(e.price3);
              item.qty = 1;
              item.iva = e.iva;
              item.subtotal = numberWithCommas(e.price * item.qty);
            }
          });
          setData(newItems);
        } else {
          setData(e?.id);
        }
      }}
    >
      <div className={`relative w-[100%] ${props.className}`} >
        <div className="relative w-[100%] cursor-default text-left">
          <Combobox.Input
            id="toFocus"
            disabled={props.disable ? true : false}
            readOnly={props.disable ? true : false}
            className={`w-full input-bg-color py-1 pl-3 pr-10 leading-5 text-gray-900 ${props.className}`}
            displayValue={(data) => {
              if (value == 0) {
                return "";
              } else {
                //  const nameWithFantasy = data.fantasyname ? `${data.name} (${data.fantasyname})` : data.name;
                return data?.name || data?.description || data?.fullname;
              }
            }}
            onChange={(event) => {
              if (props.search) {
                props.search(event.target.value);
              }
              setQuery(event.target.value);
            }}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-[1px] z-10 max-h-60 w-[145%] overflow-auto rounded-md bg-white rounded-lg py-1 text-base shadow-lg sm:text-sm">
            {filteredData?.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Sin resultados.
              </div>
            ) : (
              filteredData?.map((person) => {
                return (

                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `combobox-option relative cursor-default select-none py-1 pl-5 pr-6 text-left ${active ? "rounded-none bg-slate-600" : "text-black"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => {
                   // console.log(person); 
                      return (
                        <>
                          <span
                            className={`flex flex-row items-center truncate !text-[11px] pl-5 ${selected ? "font-medium" : "font-normal"
                              } ${active ? "another-text-white" : "text-black"}`}
                          >
                            {person?.description != undefined ? person.description + "  " : ""}
                            {person?.barcode != undefined ? person.barcode + "  " : ""}
                            {person?.ruc != undefined ? person.ruc + "  " : ""}
                            {person?.name
                              ? `${person.name}${person.fantasyname ? ` -- ${person.fantasyname}` : ""}  `
                              : ""}
                            {person?.fullname != undefined ? person.fullname + "  " : ""}
                            {person?.price != undefined ? numberWithCommas(person.price) + "  " : " "}
                            {person?.deleted != undefined && person?.deleted == 1 ? "ELIMINADO" : ""}
                            {person?.brand?.name && (
                              <span className="ml-2 text-sm text-gray-500">({person.brand.name})</span>
                            )}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-teal-600"
                                }`}
                            >
                              <CheckIcon
                                style={{
                                  fill: "white",
                                }}
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>

                );
              })
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default ComboBox;

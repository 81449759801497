import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosAuth } from "../utils/axios";
import logo from "../assets/img/logo-big.jpg";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import { setToken, setUser } from "../store/slices/mainSlice";
import logocus from "../assets/img/logo-big.jpg";
import logocusmas from "../assets/img/logo.png";
import logoapp from "../assets/img/logo-app.png";
import { usePDF } from "react-to-pdf";

const PricePrint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { priceId } = useParams();
  const [price, setPrice] = useState([]);
  const [total, setTotal] = useState(0);

  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const createMarkup = (html) => {
    return { __html: html };
  };
  const getPriceData = async () => {
    if (priceId == null) navigate("/cotizaciones");

    let priceR = await axiosAuth("/prices/" + priceId);

    if (priceR.data.error) {
      if (priceR.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let totalSum = 0;

    priceR.data.price.pricesdetails.map((detail) => {
      totalSum += detail.quantity * detail.priceAmount;
    });

    setTotal(totalSum);
    setPrice(priceR.data.price);

    setTimeout(function () {
      window.print();
      setTimeout(function () {
        window.close();
      }, 700);
    }, 700);
  };

  useEffect(() => {
    getPriceData();
  }, []);

  return (
    <>
      <div className="bg-white w-[1024px] m-auto p-5" ref={targetRef}>
        <div
          className="flex sm:flex-row flex-col pb-1"
          style={{ borderBottom: "1px solid gray" }}
        >
          <div
            className="sm:w-[50%] w-full pb-2"
            style={{ borderRight: "1px solid gray" }}
          >
            <h1 className="font-black text-2xl">COTIZACION</h1>
            <div className="pl-4">
              <p style={{ lineHeight: "16px" }} className="mt-2 text-[11px]">
                <label className="font-bold mr-[25px]">FECHA </label>
                <label className="font-bold">: </label>
                {price?.createdAt?.substr(0, 10).split("-").reverse().join("-")}
              </p>
              <p style={{ lineHeight: "16px" }} className="text-[11px]">
                <label className="font-bold mr-[13px]">NUMERO </label>
                <label className="font-bold">: </label>
                {price?.id}
              </p>
              <p
                style={{ lineHeight: "16px", whiteSpace: "nowrap" }}
                className="text-[11px]"
              >
                <label className="font-bold mr-[15px]">CLIENTE </label>
                <label className="font-bold">: </label>
                {price?.client?.name}
              </p>
              <p style={{ lineHeight: "16px" }} className="text-[11px]">
                <label className="font-bold">CONTACTO </label>
                <label className="font-bold">: </label>
                {price.client?.contacts.lenght > 0 &&
                  price.client?.contacts[0].name +
                    price.client?.contacts[0].phone}
              </p>
            </div>
          </div>
          <div className="sm:w-[50%] w-full flex flex-row justify-around">
            <img src={logocus} className="w-24 h-24 mt-1 float-right ml-5" />
            <img src={logoapp} className="w-24 h-24 mt-1 float-right ml-5" />
            <img
              src={logocusmas}
              className="w-28 h-[60px] float-right mt-5 mr-2"
            />
          </div>
        </div>

        <table
          className="mt-7 pricesTable"
          style={{ borderBottom: "1px solid black" }}
        >
          <thead className="pb-3" style={{ height: "20px" }}>
            <tr>
              <th className="w-[10%] text-[9px] font-black">CANT.</th>
              <th className="w-[50%] text-[9px] font-black">DESCRIPCION</th>
              <th className="w-[10%] text-[9px] font-black ">PR. UNITARIO</th>
              <th className="w-[20%] text-[9px] font-black pl-[65px]">
                PR. TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {price?.pricesdetails?.map((pricedetail) => (
              <tr>
                <td className="text-xs">{pricedetail.quantity}</td>
                <td className="text-left text-xs">
                  {pricedetail.item.name} <br />
                  <div className="text-xs italic" dangerouslySetInnerHTML={createMarkup(pricedetail?.observation)}>
                   
                  </div>
                </td>
                <td className="text-right text-xs">
                  {numberWithCommas(pricedetail.priceAmount)}
                </td>
                <td className="text-right text-xs">
                  {numberWithCommas(pricedetail.priceAmount * pricedetail.quantity)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="w-[100%] pricesTable priceSecondTable">
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-12">
              <td className="text-right font-bold" colSpan={20}>
                TOTAL: <span>{numberWithCommas(total)}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-center w-full">
          Atentos saludos, <br />
        </h3>
        <h3 className="text-right w-full">
          <span className="font-bold uppercase">{price?.seller?.fullname}</span>
        </h3>

        <div
          className="w-full"
          style={{
            borderTop: "2px solid black",
            marginTop: "430px",
            paddingTop: "5px",
          }}
        >
          <p className="w-full text-right leading-3 text-[9px]">
            +595 984 009 607
          </p>
          <p className="w-full text-right leading-3 text-[9px]">
            comercial@ocus.com.py
          </p>
          <p className="w-full text-right leading-3 text-[9px]">
            Vicente de Paul c/ Carmen de Peña
          </p>
          <p className="w-full text-right leading-3 text-[9px]">
            Itauguá - Paraguay
          </p>
        </div>
      </div>
    </>
  );
};

export default PricePrint;

import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Mail, Eye } from 'lucide-react';

export const TablaFacturas = ({ facturas, onReenviar, onVer }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>ID</TableHead>
          <TableHead>Fecha</TableHead>
          <TableHead>RUC</TableHead>
          <TableHead>Razón Social</TableHead>
          <TableHead>Monto (Gs.)</TableHead>
          <TableHead>Estado</TableHead>
          <TableHead>Acciones</TableHead> {/* Nueva columna para los botones */}
        </TableRow>
      </TableHeader>
      <TableBody>
        {facturas.map((factura) => (
          <TableRow key={factura.id}>
            <TableCell>{factura.id}</TableCell>
            <TableCell>{factura.fecha}</TableCell>
            <TableCell>{factura.ruc}</TableCell>
            <TableCell>{factura.razonSocial}</TableCell>
            <TableCell>{factura.monto.toLocaleString('es-PY')}</TableCell>
            <TableCell>{factura.estado}</TableCell>
            <TableCell>
              {/* Botón para reenviar */}
              <button
                onClick={() => onReenviar(factura)}
                title="Reenviar documento"
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
              >
                <Mail size={20} color="#007bff" />
              </button>
              {/* Botón para ver */}
              <button
                onClick={() => onVer(factura)}
                title="Ver documento"
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <Eye size={20} color="#28a745" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

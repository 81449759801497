import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import toast from "react-hot-toast";
import { numberWithCommas, formatDate, endpoint } from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import door from "../assets/img/door.png";
import broom from "../assets/img/broom.png";
import floppy from "../assets/img/floppy.png";
import product from "../assets/img/product.png";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { faPen, faTrash, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";

const PricesCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [action, setAction] = useState("create");

  const { width } = useViewport();
  const [selectedCurrency, setSelectedCurrency] = useState('PYG');
  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [prices, setPrices] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [totalAmount, setTotalAmount] = useState();

  const [priceId, setPriceId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [clientIdx, setClientIdx] = useState(0);
  const [clientsOpen, setClientsOpen] = useState(false);
  const [date, setDate] = useState(
    new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  );


  const [name, setName] = useState("");
  const [ruc, setRuc] = useState("");
  const [cumple, setCum] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [hood, setHood] = useState("");

  const [cityId, setCityId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [dataOriginId, setDataOriginId] = useState(0);
  const [observations, setObservations] = useState("");
  const [descriptionf, setDescriptionf] = useState("");
  const [fantasyname, setFantasyname] = useState("");
  const [search, setSearch] = useState("");
  const [potential, setPotential] = useState(0);
  const [active, setActive] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [forMesagge, setFormessage] = useState("");

  const [contacts, setContacts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [dataOrigins, setDataOrigins] = useState([]);

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const [itemsOpen, setItemsOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchArticles, setSearchArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {

    const pageNumbers = [];
    const maxPageNumbers = 6; // Number of page numbers to display

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPageNumbers - 2) {
        for (let i = 1; i < maxPageNumbers; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (maxPageNumbers - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };


  async function fetchPrices() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let pricesRequest = await axiosAuth.get(
      "/prices?page=" +
      pageQuery +
      "&companyId=" +
      user.companyId +
      "&branchId=" +
      user.branchId
    );
    let cities = await axiosAuth.get("/cities?page=" + pageQuery);

    setCities(cities.data.rows)
    let zone = await axiosAuth.get("/zones?page=" + pageQuery);
    setZones(zone.data.zones.rows)
    let dataorigins = await axiosAuth.get("/dataOrigins?page=" + pageQuery);
    setDataOrigins(dataorigins.data.dataorigins.rows)
    let companiesget = await axiosAuth.get("/companies?page=" + pageQuery);
    setCompanies(companiesget.data.rows)
    // console.log(companiesget)

    if (pricesRequest.data.error) {
      if (pricesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // PricesRequest.data.prices.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });
    //pricesRequest.data.prices.clients.rows.push({ id: "add", name: "Crear Cliente" });
    setClients(pricesRequest.data.prices.clients.rows);
    setCurrencies(pricesRequest.data.prices.currencies);
    setArticles(pricesRequest.data.prices.items.rows);

    let newItems = [];
    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          idx: i,
          id: 0,
          qty: 0,
          name: "",
          observation: "",
          descriptionf: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(prev => newItems);
    setIsFetching(false);
  }

  useEffect(() => {
    fetchPrices();
    const savedCurrencyId = localStorage.getItem('selectedCurrencyId');
    if (savedCurrencyId) {
      setCurrencyId(Number(savedCurrencyId));
    }

    const currency = currencies.find(c => c.id == currencyId);
    if (currency) {
      setSelectedCurrency(currency.name);
    }
  }, []);

  useEffect(() => {
    let client = clients.filter((client) => client.id === clientId)[0];

    //console.log(client);
    if (client != undefined) {
      if (client.id == "add") {
        clients.forEach((itm, i) => {
          if (itm.id == 'add') {
            setClientIdx(i);
            setClientsOpen(true);

          }
        });
      } else {
        setClient(client);
      }
    }



  }, [clientId]);

  useEffect(() => {
    let totalAmountSum = 0;

    items.map((item) => {
      totalAmountSum += item.qty * String(item.price).replaceAll(",", "");
    });

    setTotalAmount(numberWithCommas(totalAmountSum));

    if (items.length > 0) {
      let isFull = true;

      items.map((item) => {
        if (item.name == "") {
          isFull = false;
        }
      });

      let newItems = [...items];

      if (isFull) {
        newItems.push({
          idx: items.length + 1,
          id: 0,
          qty: 0,
          name: "",
          observation: "",
          descriptionf: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });

        setItems(newItems);
      }
    }
    checkForDuplicateId(items);

  }, [items]);

  const clearFields = () => {
    setPriceId(0);
    setClient([]);
    setClientId(0);
    setCurrencyId(1);
    let newItems = [];

    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          idx: i,
          id: 0,
          qty: 0,
          name: "",
          observation: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);
    setDeleted(0);
  };


  const checkForDuplicateId = (items) => {
    const isDuplicate = items.slice(0, -1).filter(item => item.id !== 0)
    if (isDuplicate.length > 0) {
      const lastItem = isDuplicate[isDuplicate.length - 1];
      const lastItemId = lastItem.id;
      isDuplicate.pop();
      let idExists = false;
      for (let i = 0; i < isDuplicate.length; i++) {
        if (isDuplicate[i].id === lastItemId && isDuplicate[i].id !== 0) {
          idExists = true;
          break;
        }
      }
      if (idExists) {
        alert("El producto ya existe. ¿Desea agregar?");
      }
    }
  };
  const handleSubmit = async () => {
    debugger;
    if (action == "edit") {
      return false;
    }

    if (
      clientId === 0 ||
      sellerId === 0 ||
      currencyId === 0 ||
      branchId === 0 ||
      companyId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var itemsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        item.priceAmount = item.price;
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    let createdPriceRequest = await axiosAuth.post("/prices", {
      clientId,
      sellerId,
      currencyId,
      branchId,
      companyId,
      pricesdetail: itemsToSend,
      totalAmount: Number(String(totalAmount).replaceAll(",", "")),
      deleted,
    });

    if (createdPriceRequest.data.error) {
      if (createdPriceRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newPrices = [...prices];
    newPrices.push(createdPriceRequest.data);
    setPrices(newPrices);

    toast.success("Cotizacion creada correctamente!");
    setOpenPrint(true);
    clearFields();
    setPriceId(createdPriceRequest.data.id);
    // fetchPrices();
  };
  const handleSubmitClien = async () => {

    let createdClient = await axiosAuth.post("/clients", {
      name,
      ruc,
      address,
      phone,
      email,
      hood,
      companyId,
      cityId,
      zoneId,
      dataOriginId,
      observations,
      potential,
      active,
      deleted,
      cumple,
      fantasyname,
    });

    if (createdClient.data.error) {
      if (createdClient.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }

      if (createdClient.data.message === "ruc") {
        window.alert("Ya existe un cliente con el RUC ingresado!");
      }

      setFormessage(createdClient.data.message);
    } else {
      let newClients = [...clients];
      newClients.push(createdClient.data);
      //console.log(createdClient.data);
      setClientId(createdClient.data.id);
      setClient(createdClient.data)
      const reNewClients = newClients.filter(client => client.id !== "add");
      //console.log(reNewClients);
      setClients(reNewClients);


    }
    setClientsOpen(false);

  };

  useEffect(() => {
    setPriceId(priceId);
  }, [priceId]);

  const deletePrice = async (priceId) => {
    if (window.confirm("Desea eliminar esta cotizacion ?")) {
      let PriceDeleteRequest = await axiosAuth.delete("/prices", {
        params: { id: priceId },
      });

      if (PriceDeleteRequest.data.error) {
        if (PriceDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPricesObj = [];

      prices.map((price) => {
        if (price.id !== priceId) {
          newPricesObj.push(price);
        } else {
          if (user.roleId == 1) {
            price.deleted = 1;
            newPricesObj.push(price);
          }
        }
        return "";
      });

      setPrices(newPricesObj);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    if (searchClientsRequest.data.clients.rows.length > 0) {
      //searchClientsRequest.data.clients.rows.push({ id: "add", name: "Crear Cliente" });
      setClients(searchClientsRequest.data.clients.rows);
      //console.log(searchClientsRequest.data.clients.rows);
    } else {
      const searchPotentialClientsRequest = await axiosAuth.get(
        "/searchPotentialClients?search=" + search
      );
      //searchPotentialClientsRequest.data.potentialClients.rows.push({ id: "add", name: "Crear Cliente" });
      setClients(searchPotentialClientsRequest.data.potentialClients.rows);
      // console.log(searchClientsRequest.data.clients.rows);
    }

  };

  const searchItems = async (search) => {
    let boole = "modal" ? 1 : 0;
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + search + "&boole=" + boole
    );
    //console.log(searchItemsRequest);
    setSearchArticles(searchItemsRequest.data.items.rows);
    setTotalPage(searchItemsRequest.data.items.pages)

    setArticles(searchItemsRequest.data.items.rows);
  };

  const deleteRow = (i) => {
    // Copia del array items
    const newItems = [...items];

    // Elimina el elemento en la posición i
    newItems.splice(i, 1);

    // Actualiza el estado con el nuevo array sin el elemento eliminado
    setItems(newItems);
  }
  useEffect(() => {
    let newItems = [...items];

    newItems.map((itemMap) => {
      if (itemMap.idx == item?.idx) {
        itemMap.observation = item.observation;
        itemMap.descriptionf = item.descriptionf;
      }
    });

    setItems(newItems);
  }, [item?.observation]);

  const handleCurrencyChange = (e) => {
    const selectedCurrencyId = e.target.value;
    setCurrencyId(selectedCurrencyId);
    localStorage.setItem('selectedCurrencyId', selectedCurrencyId);
    const currency = currencies.find(c => c.id == selectedCurrencyId);
    if (currency) {
      setSelectedCurrency(currency.name);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={itemsOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setItemsOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[800px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Buscar articulos
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <div class="relative mt-2 rounded-md shadow-sm">
                                <input
                                  type="text"
                                  placeholder="Busque aqui el articulo por nombre o codigo de barras"
                                  class="text-modal-search block w-full rounded-md border-0 py-2 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                  onChange={(e) => {
                                    setSearchText(e.target.value)
                                    searchItems(e.target.value, 'modal')
                                  }}
                                  style={{ fontSize: "13px" }}
                                />
                              </div>

                              <ul className="max-h-[50vh] overflow-auto">
                                {searchArticles.length == 0 ? (
                                  <li className="flex flex-row m-1 p-2">
                                    <div className="text-center w-full">No encontramos resultados</div>
                                  </li>
                                ) : (
                                  searchArticles.map((article) => (
                                    <li
                                      key={article.id}
                                      className="flex flex-row m-1 hover:bg-gray-200 cursor-pointer p-2"
                                      style={{
                                        borderBottom: "1px solid lightgray",
                                      }}
                                      onClick={() => {
                                        let nextEmptyIndex = null;
                                        let newItems = [...items];
                                        const BreakException = {};

                                        try {
                                          newItems.forEach((newItem, i) => {
                                            if (newItem.id === 0) {
                                              nextEmptyIndex = i;
                                              throw BreakException;
                                            }
                                          });
                                        } catch (e) {
                                          if (e !== BreakException) throw e;
                                        }

                                        if (nextEmptyIndex === null) {
                                          newItems.push({
                                            id: article.id,
                                            qty: 1,
                                            name: article.name,
                                            price: article.price,
                                            subtotal: article.price,
                                            iva: article.iva,
                                          });
                                        } else {
                                          newItems[nextEmptyIndex] = {
                                            id: article.id,
                                            qty: 1,
                                            name: article.name,
                                            price: article.price,
                                            subtotal: article.price,
                                            iva: article.iva,
                                          };
                                        }

                                        setItems((prev) => newItems);
                                        setItemsOpen(false);
                                      }}
                                    >
                                      <div>
                                        {article?.image ? (
                                          <img
                                            className="h-[70px] m-2 mr-5"
                                            src={`${endpoint}itemImages/${article.image}`}
                                            alt={article.name}
                                          />
                                        ) : (
                                          <img
                                            className="h-[70px] m-2 mr-5"
                                            src={`${endpoint}itemImages/default.png`}
                                            alt="default"
                                          />
                                        )}
                                      </div>
                                      <div className="flex flex-col text-left">
                                        <label className="!text-[9px]">
                                          <span className="!text-[11px]" style={{ fontWeight: "900" }}>
                                            {article.name}
                                          </span>
                                        </label>
                                        <label className="!text-[11px]" style={{ fontWeight: "bold" }}>
                                          <span className="!text-[9px]" style={{ fontWeight: "bold" }}>
                                            PRECIO:
                                          </span>{" "}
                                          {numberWithCommas(article.price)}
                                        </label>
                                        <label className="!text-[11px]" style={{ fontWeight: "bold" }}>
                                          <span className="!text-[9px]" style={{ fontWeight: "bold" }}>
                                            COD BARRAS:
                                          </span>{" "}
                                          {article.barcode}
                                        </label>
                                        <label className="!text-[11px]" style={{ fontWeight: "bold" }}>
                                          <span className="!text-[9px]" style={{ fontWeight: "bold" }}>
                                            STOCK:
                                          </span>
                                        </label>
                                      </div>
                                    </li>
                                  ))
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-right flex sm:px-6" style={{ justifyContent: "right" }}>
                      <nav className="flex">
                        <button
                          onClick={handlePrev}
                          disabled={currentPage === 1}
                          className={`h-[49px] px-4 py-2 mr-2 rounded focus:outline-none focus:ring bg-gray-600${currentPage === 1
                            ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
                            : 'text-gray-700 bg-gray-200 hover:bg-gray-300 bg-gray-600'
                            }`}
                        >
                          Anterior
                        </button>
                        <ul className="flex">
                          {renderPageNumbers().map((page, index) => (
                            <li key={index}>
                              {page === '...' ? (
                                <span className="px-4 py-2 mx-1">...</span>
                              ) : (
                                <button
                                  onClick={() => handleClick(page)}
                                  className={`h-[49px] w-[49px] px-4 py-2 mx-1 rounded focus:outline-none focus:ring ${currentPage === page
                                    ? 'text-white bg-blue-500 hover:bg-blue-600'
                                    : 'text-gray-700 bg-gray-600 hover:bg-gray-300'
                                    }`}
                                >
                                  {page}
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className={`h-[49px] px-4 py-2 ml-2 rounded focus:outline-none focus:ring bg-gray-600 ${currentPage === totalPages
                            ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
                            : 'text-gray-700 bg-gray-200 hover:bg-gray-300'
                            }`}
                        >
                          Siguiente
                        </button>
                      </nav>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-right m-1 flex-col justify-right items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setItemsOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openPrint} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpenPrint(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    style={{ marginTop: 150 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Imprimir o guardar PDF
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded-3xl"></form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setOpenPrint(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          navigate("/cotizacionpdf/" + priceId);
                        }}
                      >
                        GUARDAR PDF
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          navigate("/imprimircotizacion/" + priceId);
                        }}
                      >
                        IMPRIMIR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed w-[95%] inset-0 z-10 m-auto overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative sm:w-[100%] transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[320px]"
                    style={{ marginTop: 10 }}
                  >
                    <div className="bg-white px-4 pb-2 pt-5 sm:p-4 sm:pb-0">
                      <div className="w-[300px]">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-xl"
                          >
                            {item.name}

                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                              
                                
                                <div className="bg-white mb-4 flex flex-row justify-center">
                                  <ReactQuill
                                    theme="snow"
                                    value={item?.descriptionf || "<p>Este es un texto inicial de prueba</p>"}
                                    onChange={(value) => {
                                      console.log(item);
                                      // Actualiza directamente el campo 'descriptionf' en el item
                                      setItem({ ...item, descriptionf: value, observation: value });

                                      // Propaga el cambio a la lista de items
                                      setItems((prevItems) =>
                                        prevItems.map((currentItem) =>
                                          currentItem.idx === item.idx
                                            ? { ...currentItem, descriptionf: value }
                                            : currentItem
                                        )
                                      );
                                    }}
                                    className="w-full"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 pt-0 justify-center sm:justify-end flex sm:px-6 relative top-[-8px] right-[-5px]">
                      <button
                        type="button"
                        className="inline-flex flex-col w-[90%] h-[63px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-4" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={true}>
          <div className="flex flex-row">
            <div className="w-[60%] md:w-[20%] border-slate-200 border-r-2">
              <h1 className="ml-5 mt-5 text-2xl  title secondary-color font-family-thin">
                Cotizar
              </h1>
              <DateComponent className="sm:block hidden" />
            </div>
            <div className="w-[100%] mx-auto overflow-auto pb-0 pt-0">
              <div className="bg-white px-4 p-1 text-center rounded-3xl">
                <div className="w-full">
                  <div className="mt-2">
                    <div className="w-full flex flex-col">
                      {width > 550 ? (
                        <>
                          <div className="flex flex-row w-full">
                            <div className="w-[50%]">
                              <div className="mb-[1px]" style={{ borderTop: "1px solid lightgray" }}>
                                <ComboBox
                                  data={clients}
                                  setData={setClientId}
                                  value={clientId}
                                  placeholder="Cliente"
                                  search={searchClients}
                                  className="h-[28.1px] border-t-1"
                                />
                              </div>

                              <div>
                                <input
                                  className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                  id="RUC"
                                  type="text"
                                  disabled
                                  readOnly
                                  value={client?.ruc || ""}
                                  placeholder="RUC"
                                  onChange={(e) => { }}
                                />
                              </div>

                              <div>
                                <input
                                  className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                  id="address"
                                  type="text"
                                  readOnly
                                  disabled
                                  value={client?.address || ""}
                                  placeholder="Direccion"
                                  onChange={(e) => { }}
                                />
                              </div>

                              <div>
                                <a
                                  target="_blank"
                                  href={
                                    client?.phone?.replaceAll(" ", "") != "" &&
                                      client?.phone?.replaceAll(" ", "") != " "
                                      ? "https://wa.me/" +
                                      "+595" +
                                      client?.phone
                                        ?.replaceAll(" ", "")
                                        .replace("+", "")
                                        .replace("595", "")
                                      : "/#"
                                  }
                                >
                                  <input
                                    className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                    id="phone"
                                    type="text"
                                    readOnly
                                    value={client?.phone || ""}
                                    placeholder="Telefono"
                                    onChange={(e) => { }}
                                  />
                                </a>
                              </div>
                            </div>

                            <div className="w-[50%] ml-[2px]">
                              <div className="mb-[1px]">
                                <input
                                  className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  h-[28.1px]"
                                  id="date"
                                  value={date}
                                  type="date"
                                  placeholder="Fecha"
                                  onChange={(e) => {
                                    setDate(e.target.value);
                                  }}
                                />
                              </div>

                              <div className="mb-[1px]">
                                <select
                                  className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize  h-[28.1px]"
                                  id="branch"
                                  value={currencyId}
                                  onChange={handleCurrencyChange}
                                >
                                  <option value={0}>Moneda</option>
                                  {currencies.map((currency, i) => (
                                    <option value={currency.id} key={i}>
                                      Moneda: {currency.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="flex flex-row">
                                <div className="w-full">
                                  <select
                                    className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                    id="branch"
                                    readOnly
                                    disabled
                                    value={branchId}
                                    onChange={(e) => {
                                      setBranchId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Sucursal</option>
                                    <option value={user.branch.id}>
                                      Sucursal: {user.branch.description}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div>
                                <select
                                  className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                  id="sellerId"
                                  disabled
                                  readOnly
                                  value={sellerId}
                                  onChange={(e) => { }}
                                >
                                  <option value={0}>Vendedor</option>
                                  <option value={user.id}>
                                    Vendedor: {user.fullname}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    clientId == 0 ? "bg-red-300" : "bg-green-200",
                                    selected
                                      ? "bg-white font-bold"
                                      : "primary-color hover:text-slate-500"
                                  )
                                }
                              >
                                Cliente
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    branchId == 0 ||
                                      currencyId == 0 ||
                                      date == "" ||
                                      sellerId == 0
                                      ? "bg-red-300"
                                      : "bg-green-200",
                                    selected
                                      ? "bg-white font-bold"
                                      : "primary-color hover:text-slate-500"
                                  )
                                }
                              >
                                Datos
                              </Tab>
                            </Tab.List>
                            <Tab.Panels>
                              <Tab.Panel>
                                <Transition.Child
                                  as="div"
                                  enter="ease-out duration-500"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="ease-in duration-300"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <div className="mb-[2px]">
                                    <ComboBox
                                      data={clients}
                                      setData={setClientId}
                                      value={clientId}
                                      className=" h-[28.1px] !border-b-2"
                                      placeholder="Cliente"
                                      search={searchClients}
                                    />
                                  </div>

                                  <div className="mb-[2px]">
                                    <input
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize  h-[28.1px]"
                                      id="RUC"
                                      type="text"
                                      disabled
                                      readOnly
                                      value={client?.ruc || ""}
                                      placeholder="RUC"
                                      onChange={(e) => { }}
                                    />
                                  </div>

                                  <div className="mb-[2px]">
                                    <input
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize  h-[28.1px]"
                                      id="address"
                                      type="text"
                                      readOnly
                                      disabled
                                      value={client?.address || ""}
                                      placeholder="Direccion"
                                      onChange={(e) => { }}
                                    />
                                  </div>

                                  <div>
                                    <a
                                      target="_blank"
                                      href={
                                        client?.phone?.replaceAll(" ", "") !=
                                          "" &&
                                          client?.phone?.replaceAll(" ", "") != " "
                                          ? "https://wa.me/" +
                                          "+595" +
                                          client?.phone
                                            ?.replaceAll(" ", "")
                                            .replace("+", "")
                                            .replace("595", "")
                                          : "/#"
                                      }
                                    >
                                      <input
                                        className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                        id="phone"
                                        type="text"
                                        readOnly
                                        value={client?.phone || ""}
                                        placeholder="Telefono"
                                        onChange={(e) => { }}
                                      />
                                    </a>
                                  </div>
                                </Transition.Child>
                              </Tab.Panel>
                              <Tab.Panel>
                                <Transition.Child
                                  as="div"
                                  enter="ease-out duration-500"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="ease-in duration-300"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <div className="mb-[2px] flex flex-row w-full">
                                    <div className="w-full">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize  h-[28.1px]"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={branchId}
                                        onChange={(e) => {
                                          setBranchId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Sucursal</option>
                                        <option value={user.branch.id}>
                                          Sucursal: {user.branch.description}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="mb-[2px]">
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="branch"
                                      value={currencyId}
                                      onChange={(e) => {
                                        setCurrencyId(e.target.value);
                                      }}
                                    >
                                      <option value={0}>Moneda</option>
                                      {currencies.map((currency, i) => (
                                        <option value={currency.id} key={i}>
                                          Moneda: {currency.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="mb-[2px]">
                                    <input
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="date"
                                      value={date}
                                      type="date"
                                      placeholder="Fecha"
                                      onChange={(e) => {
                                        setDate(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="sellerId"
                                      disabled
                                      readOnly
                                      value={sellerId}
                                      onChange={(e) => { }}
                                    >
                                      <option value={0}>Vendedor</option>
                                      <option value={user.id}>
                                        Vendedor: {user.fullname}
                                      </option>
                                    </select>
                                  </div>
                                </Transition.Child>
                              </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                        </>
                      )}


                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
          <div className="w-[100%] p-4 mx-auto overflow-auto pt-0">
            <div className="bg-white  text-center rounded-3xl">
              <div className="w-full">
                <div className="mb-1">
                  <div className="prices-table w-full sm:min-w-[695px] ">
                    <div className="text-gray">
                      {width > 620 ? (
                        <div
                          className="tr flex mb-[1px] primary-color-bg !text-white"
                          style={{ lineHeight: 2 }}
                        >
                          <div className="w-[10%] text-[11px] font-bold">
                            ID.
                          </div>
                          <div className="w-[43%] text-[11px] font-bold">
                            PRODUCTO
                          </div>
                          <div className="w-[10%] text-[11px] font-bold">
                            DESCRIPCION
                          </div>
                          <div className="w-[10%] text-[11px] font-bold">
                            CANTIDAD
                          </div>
                          <div className="w-[15%] text-[11px] font-bold">
                            PRECIO
                          </div>
                          <div className="w-[15%] text-[11px] font-bold">
                            TOTAL
                          </div>
                          <div className="w-[5%] text-[11px] font-bold">


                            <FontAwesomeIcon
                              className="mt-2 cursor-pointer white-color"
                              icon={faTrash}
                            />

                          </div>

                        </div>
                      ) : (
                        <div className="tr flex">
                          <div className="w-[15%] font-bold">
                            ID.
                          </div>
                          <div className="w-[70%] font-bold">
                            PRODUCTO
                          </div>
                          <div className="w-[15%] font-bold">
                            CANT
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="h-[230px] overflow-y-auto overflow-x-hidden">

                      {isFetching && (
                        <div className="tr">
                          <div className="td text-center" colSpan={20}>
                            <Lottie
                              animationData={loadingAnimation}
                              style={{ width: 100, height: 100, margin: "0 auto" }}
                              loop={true}
                            />
                          </div>
                        </div>
                      )}

                      {width > 620
                        ? items.map((item, i) => (
                          <div className="tr flex">
                            <div className="td w-[10%] ">
                              <input
                                className="input-bg-color-custom text-sm h-[28.1px] py-1 px-3 w-full text-center"
                                type="text"

                                name="code"
                                value={item.id}
                              />
                            </div>

                            <div className="td w-[43%] ">
                              <ComboBox
                                data={articles}
                                value={item.id}
                                setData={setItems}
                                items={items}
                                index={i}
                                search={searchItems}
                                className="h-[28.1px]"
                              />
                            </div>
                            <div className="td w-[10%] ">
                              <input
                                className="input-bg-color-custom w-full text-center h-[28.1px]"
                                type="text"
                                name="observation"
                                value={item.observation}
                                onClick={() => {
                                  console.log(item)
                                  setItem(item);
                                  setOpen(!open);
                                }}
                              />
                            </div>
                            <div className="td w-[10%] ">
                              <input
                                className="input-bg-color-custom w-full text-center h-[28.1px]"
                                type="text"
                                name="qty"
                                value={item.qty}
                                onChange={(e) => {
                                  let newItems = [...items];
                                  newItems.map((item, it) => {
                                    if (i == it) {
                                      item.qty = e.target.value;
                                      item.subtotal =
                                        String(item.price).replaceAll(
                                          ",",
                                          ""
                                        ) * item.qty;
                                      item.subtotal =
                                        numberWithCommas(
                                          item.subtotal
                                        );
                                    }
                                  });
                                  setItems(newItems);
                                }}
                              />
                            </div>
                            <div className="td w-[15%] ">
                              <input
                                className="input-bg-color-custom py-1 px-3 r w-full text-right h-[28.1px]"
                                type="text"
                                name="price"
                                value={numberWithCommas(item.price)}
                                placeholder="Precio"
                                onChange={(e) => {
                                  let newItems = [...items];
                                  newItems.map((item, it) => {
                                    if (i == it) {
                                      item.price = String(
                                        e.target.value
                                      ).replaceAll(",", "");
                                      item.subtotal =
                                        String(item.price).replaceAll(
                                          ",",
                                          ""
                                        ) * item.qty;
                                      item.subtotal =
                                        numberWithCommas(
                                          item.subtotal
                                        );
                                    }
                                  });
                                  setItems(newItems);
                                }}
                              />
                            </div>
                            <div className="td w-[15%] ">
                              <input
                                className="input-bg-color-custom py-1 px-3 w-full text-right h-[28.1px]"
                                type="text"

                                name="subtotal"
                                value={numberWithCommas(
                                  item.subtotal
                                )}
                              />
                            </div>
                            <div className="td w-[5%] ">
                              <button
                                className="bg-transparent"
                                onClick={() => {
                                  deleteRow(i);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="m-1 cursor-pointer secondary-color"
                                  icon={faTrash}
                                />
                              </button>
                            </div>
                          </div>
                        ))
                        : items.map((item, i) => (
                          <div
                            className="tr flex flex-col m-1 pb-1"
                            style={{
                              borderBottom:
                                "1px solid rgba(0,0,0,0.5)",
                            }}
                          >
                            <div className="w-full flex flex-row">
                              <div className="td w-[100%] ">
                                <label>Articulo: </label>
                                <ComboBox
                                  data={articles}
                                  value={item.id}
                                  setData={setItems}
                                  items={items}
                                  index={i}
                                  className="h-[32px]"
                                  search={searchItems}
                                />
                              </div>
                            </div>

                            <div className="w-full flex flex-col">
                              <label>Observacion:</label>
                              <input
                                className="input-bg-color-custom  py-1 px-3 w-full text-center"
                                type="text"
                                name="observation"
                                onClick={() => {
                                  setItem(item);
                                  setOpen(!open);
                                }}
                                value={item.observation}
                                onChange={(e) => {
                                  let newItems = [...items];
                                  newItems.map((item, it) => {
                                    if (i == it) {
                                      item.observation =
                                        e.target.value;
                                    }
                                  });
                                  setItems(newItems);
                                }}
                              />
                            </div>

                            <div className="w-full flex flex-col">
                              <label>Id Item: </label>
                              <div className="td w-[100%] ">
                                <input
                                  className="input-bg-color-custom py-1 px-3 w-full text-center"
                                  type="text"
                                  readOnly
                                  disabled
                                  name="code"
                                  value={item.id}
                                />
                              </div>
                            </div>

                            <div className="w-full flex flex-row">
                              <div className="td w-[50%] ">
                                <label>Cantidad: </label>
                                <input
                                  className="input-bg-color-custom py-1 px-3 w-full text-center"
                                  type="text"
                                  name="qty"
                                  value={item.qty}
                                  onChange={(e) => {
                                    let newItems = [...items];
                                    newItems.map((item, it) => {
                                      if (i == it) {
                                        item.qty = e.target.value;
                                        item.subtotal =
                                          String(
                                            item.price
                                          ).replaceAll(",", "") *
                                          item.qty;
                                        item.subtotal =
                                          numberWithCommas(
                                            item.subtotal
                                          );
                                      }
                                    });
                                    setItems(newItems);
                                  }}
                                />
                              </div>

                              <div className="td w-[50%] ">
                                <label>IVA: </label>
                                <input
                                  className="input-bg-color-custom py-1 px-3 w-full text-center"
                                  type="text"
                                  name="iva"
                                  value={item.iva}
                                />
                              </div>
                            </div>

                            <div className="w-full flex flex-row">
                              <div className="td w-[50%] ">
                                <label>Precio: </label>
                                <input
                                  className="input-bg-color-custom py-1 px-3 w-full text-center"
                                  type="text"
                                  name="price"
                                  value={numberWithCommas(item.price)}
                                  placeholder="Precio"
                                  onChange={(e) => {
                                    let newItems = [...items];
                                    newItems.map((item, it) => {
                                      if (i == it) {
                                        item.price = String(
                                          e.target.value
                                        ).replaceAll(",", "");
                                        item.subtotal =
                                          String(
                                            item.price
                                          ).replaceAll(",", "") *
                                          item.qty;
                                        item.subtotal =
                                          numberWithCommas(
                                            item.subtotal
                                          );
                                      }
                                    });
                                    setItems(newItems);
                                  }}
                                />
                              </div>
                              <div className="td w-[50%] ">
                                <label>Subtotal: </label>
                                <input
                                  className="input-bg-color-custom py-1 px-3 w-full text-right"
                                  type="text"
                                  readOnly
                                  disabled
                                  name="subtotal"
                                  value={numberWithCommas(
                                    item.subtotal
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 relative sm:top-[0] top-[10px]">

              <div className="tr w-[400px] flex flex-row justify-end mt-1 items-center float-right relative right-[57px]">
                <div className="td w-[100px] text-right text-sm font-bold mr-1 r">
                  TOTAL {selectedCurrency}
                </div>

                <div
                  className="td w-[50%] pt-1 mr-[1px] relative top-[-3px]"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <div
                    className="w-[100%] relative"
                    style={{ bottom: "5px", borderTop: "1px solid black" }}
                  ></div>
                  <input
                    className="!w-[204px] ternary-bg-color py-1 px-3 w-full text-right font-bold h-[28.1px]"
                    type="text"
                    style={{ background: "transparent", border: "none" }}

                    name="total"
                    value={totalAmount}
                    placeholder="Total"
                  />
                  <div
                    className="w-[100%] relative"
                    style={{ bottom: "-4px", borderTop: "1px solid black" }}
                  ></div>
                </div>
              </div>
              <button
                type="button"
                className="relative left-[-15px] sm:top-[-5px] top-[-10px] inline-flex w-[68px] h-[63px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-2"
                onClick={() => {
                  setItemsOpen(true);
                }}
              >
                <img src={product} className="w-8" />
                ARTICULOS
              </button>
              <button
                type="button"
                className="relative left-[-15px] sm:top-[-5px] top-[-10px] inline-flex w-[68px] h-[63px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-2"
                onClick={() => {
                  setClientsOpen(true);
                }}
              >
                <FontAwesomeIcon className="m-1 cursor-pointer secondary-color !text-[20px]" icon={faPuzzlePiece} />
                CREAR CLIENTE
              </button>
              <button
                type="button"
                className="relative left-[-15px] sm:top-[-5px] top-[-10px] inline-flex w-[68px] h-[63px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-2"
                onClick={() => {
                  clearFields();
                }}
                style={{ alignItems: "center" }}
              >
                <img src={broom} className="w-8" />
                LIMPIAR
              </button>
              <button
                readOnly={action == "edit" ? true : false}
                disabled={action == "edit" ? true : false}
                type="button"
                className="relative left-[-15px] sm:top-[-5px] top-[-10px] inline-flex w-[68px] h-[63px] float-left m-1 ml-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-2"
                style={{ alignItems: "center", marginLeft: "2.5px" }}
                onClick={() => handleSubmit()}
              >
                <img src={floppy} className="w-8" />
                GUARDAR
              </button>
              <button
                readOnly={action == "edit" ? true : false}
                disabled={action == "edit" ? true : false}
                type="button"
                className="relative left-[-15px] sm:top-[-5px] top-[-10px] inline-flex w-[68px] h-[63px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-2"
                style={{ alignItems: "center" }}
                onClick={() => navigate("/")}
              >
                <img src={door} className="w-5" />
                SALIR
              </button>


            </div>
          </div>
        </Transition.Root>
        <Transition.Root show={clientsOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className={`relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-4 ${action == "edit" ? "w-[90%]" : "w-[45%]"
                      } ${width > 1024 ? "w-[45%]" : "w-[90%]"}`}
                    style={{
                      marginTop: action == "edit" ? 400 : 0,
                      marginTop: width > 1024 ? 0 : 100,
                    }}
                  >
                    <div className="bg-white px-4 pb-4 pt-1 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-8 primary-color text-2xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} cliente
                          </Dialog.Title>
                          <div className="mt-1">
                            <div className="w-full">
                              <form
                                className="bg-white rounded w-full"
                                action=""
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <div className="flex flex-col md:flex-row w-full">
                                  <div
                                    className={`${action == "edit"
                                      ? "w-[100%] md:[50%]"
                                      : "w-[100%]"
                                      } sm:border-none border-b-2 border-gray-600 pb-3`}
                                  >
                                    {action === "edit" && (
                                      <div className="mb-[1px] flex flex-row items-center">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label
                                          className="block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="id"
                                        >
                                          Id:
                                        </label>
                                        &nbsp;
                                        <input
                                          readOnly
                                          className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                          id="id"
                                          type="text"
                                          value={clientId}
                                        />
                                      </div>
                                    )}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="name"
                                      >
                                        Nombre:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="name"
                                      >
                                        Nombre Fantasia:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                        id="name"
                                        type="text"
                                        value={fantasyname}
                                        onChange={(e) =>
                                          setFantasyname(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[0.5px]"
                                        htmlFor="ruc"
                                      >
                                        RUC:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  h-[28.1px]"
                                        id="ruc"
                                        type="text"
                                        value={ruc}
                                        onChange={(e) => setRuc(e.target.value)}
                                      />
                                    </div>
                                    <div className="mb-[1px] flex flex-row items-center">

                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[0.5px]"
                                        htmlFor="Cumpleaños"
                                      >
                                        Cumpleaños:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  h-[28.1px]"
                                        id="cumple"
                                        type="date"
                                        value={cumple}
                                        onChange={(e) => setCum(e.target.value)}
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                        htmlFor="address"
                                      >
                                        Direccion:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  h-[28.1px]"
                                        id="address"
                                        type="text"
                                        value={address}
                                        onChange={(e) =>
                                          setAddress(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                        htmlFor="phone"
                                      >
                                        Telefono:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mb-[1px] h-[28.1px]"
                                        id="phone"
                                        type="text"
                                        value={phone}
                                        onChange={(e) =>
                                          setPhone(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="email"
                                      >
                                        Email:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="email"
                                        type="text"
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="hood"
                                      >
                                        Barrio:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="hood"
                                        type="text"
                                        value={hood}
                                        onChange={(e) =>
                                          setHood(e.target.value)
                                        }
                                      />
                                    </div>

                                    {user.roleId == 1 && (
                                      <>
                                        <div className="mb-[1px] flex flex-row items-center">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label
                                            className="block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="companyId"
                                          >
                                            Empresa:
                                          </label>
                                          &nbsp;
                                          <ComboBox
                                            data={companies}
                                            setData={setCompanyId}
                                            value={companyId}
                                            className="border h-[27px] w-[100.5%]"
                                          />
                                        </div>
                                      </>
                                    )}
                                    {/* <select
                                    className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="companyId"
                                    value={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Elija una empresa</option>
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </select> */}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px]"
                                        htmlFor="cityId"
                                      >
                                        Ciudad:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="cityId"
                                        value={cityId}
                                        onChange={(e) => {
                                          setCityId(parseInt(e.target.value));
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una ciudad
                                        </option>
                                        {cities.map((city) => (
                                          <option key={city.id} value={city.id}>
                                            {city.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="zoneId"
                                      >
                                        Zona:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="zoneId"
                                        value={zoneId}
                                        onChange={(e) => {
                                          setZoneId(parseInt(e.target.value));
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una zona
                                        </option>
                                        {zones.map((zone) => (
                                          <option key={zone.id} value={zone.id}>
                                            {zone.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="zoneId"
                                      >
                                        Origen:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="zoneId"
                                        value={dataOriginId}
                                        onChange={(e) => {
                                          setDataOriginId(parseInt(e.target.value));
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un origen
                                        </option>
                                        {dataOrigins.map((dataOrigin) => (
                                          <option key={dataOrigin.id} value={dataOrigin.id}>
                                            {dataOrigin.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="observations"
                                      >
                                        Observacion:
                                      </label>
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="observations"
                                        type="text"
                                        value={observations}
                                        onChange={(e) =>
                                          setObservations(e.target.value)
                                        }
                                      />
                                    </div>

                                    {/* <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="potential"
                                      >
                                        Potencial:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[28.1px]"
                                        id="potential"
                                        value={potential}
                                        onChange={(e) =>
                                          setPotential(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div> */}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="active"
                                      >
                                        Activo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[27px]"
                                        id="active"
                                        value={active}
                                        onChange={(e) =>
                                          setActive(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    {user.roleId == 1 && (
                                      <div className="mb-[1px] flex flex-row items-center">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label
                                          className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                          htmlFor="deleted"
                                        >
                                          Borrado:
                                        </label>
                                        &nbsp;
                                        <select
                                          className="input-bg-color appearance-none border w-[101%] py-2 px-3 text-gray-700 leading-[10px] focus:outline-none focus:shadow-outline h-[27px]"
                                          id="deleted"
                                          value={deleted}
                                          onChange={(e) =>
                                            setDeleted(parseInt(e.target.value))
                                          }
                                        >
                                          <option value={0}>No</option>
                                          <option value={1}>Si</option>
                                        </select>
                                      </div>
                                    )}
                                  </div>

                                  {action == "edit" && (
                                    <div className="w-[100%] md:[50%] mt-3 md:mt-0 flex flex-col">
                                      <div className="w-full max-h-[30vh] overflow-auto ml-1">
                                        <h2 className="text-center primary-color-bg !text-white uppercase text-[12px]">
                                          Contactos
                                        </h2>
                                        <table className="text-[12px] relative !top-0 !w-full">
                                          <thead className="primary-color-bg">
                                            <tr>
                                              <th className="!text-white text-[11px] font-thin">
                                                Nombre
                                              </th>
                                              <th className="!text-white text-[11px] w-[23%] font-thin">
                                                Telefono
                                              </th>
                                              <th className="!text-white text-[11px] font-thin">
                                                Email
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-[10px]">
                                            {width > 550 &&
                                              contacts.length > 0 &&
                                              contacts.map((contact) => (
                                                <tr>
                                                  <td className="text-left">
                                                    {contact.name}
                                                  </td>
                                                  <td>{contact.phone}</td>
                                                  <td className="text-left">
                                                    {contact.email}
                                                  </td>
                                                </tr>
                                              ))}

                                            {width < 550 &&
                                              contacts.length > 0 &&
                                              contacts.map((contact) => (
                                                <tr>
                                                  <td
                                                    className="text-left border-b-[1px] border-gray-600"
                                                    colSpan={3}
                                                  >
                                                    <b className="uppercase">
                                                      Nombre:{" "}
                                                    </b>
                                                    {contact.name}
                                                    <br />
                                                    <b className="uppercase">
                                                      Telefono:{" "}
                                                    </b>
                                                    {contact.phone}
                                                    <br />
                                                    <b className="uppercase">
                                                      Email:{" "}
                                                    </b>
                                                    {contact.email}
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="flex flex-col justify-center sm:flex-row m-1 border-none sm:border-t-[1px] sm:border-b-2 border-gray-600">
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-name"
                                          placeholder="Nombre"
                                          onChange={(e) =>
                                            setContactName(e.target.value)
                                          }
                                          value={contactName}
                                          type="text"
                                        />
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-phone"
                                          placeholder="Telefono"
                                          onChange={(e) =>
                                            setContactPhone(e.target.value)
                                          }
                                          value={contactPhone}
                                          type="text"
                                        />
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-email"
                                          placeholder="Email"
                                          onChange={(e) =>
                                            setContactEmail(e.target.value)
                                          }
                                          value={contactEmail}
                                          type="text"
                                        />
                                        <button
                                          onClick={async () => {
                                            if (
                                              contactName == "" ||
                                              contactEmail == "" ||
                                              contactPhone == ""
                                            ) {
                                              alert(
                                                "Debe completar los campos para guardar el contacto"
                                              );
                                              return false;
                                            }

                                            let createContactRequest =
                                              await axiosAuth.post(
                                                "/contacts",
                                                {
                                                  clientId,
                                                  name: contactName,
                                                  phone: contactPhone,
                                                  email: contactEmail,
                                                }
                                              );

                                            let newContacts = [...contacts];
                                            newContacts.push(
                                              createContactRequest.data
                                            );
                                            setContacts(newContacts);

                                            let newClients = [...clients];
                                            newClients.map((client) => {
                                              if (client.id == clientId) {
                                                client.contacts = newContacts;
                                              }
                                            });
                                            setClients(newClients);

                                            setTimeout(function () {
                                              setContactName("");
                                              setContactPhone("");
                                              setContactEmail("");
                                            }, 1000);
                                          }}
                                          className="p-1 mx-auto h-8"
                                        >
                                          <img
                                            src={floppy}
                                            className="w-8 relative top-[-6px]"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 pt-0 justify-start flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] h-[68px] float-left flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px]"
                        style={{ alignItems: "center" }}
                        onClick={() => {

                          setClientsOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] h-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px] top-[-4px] sm:top-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmitClien()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Layout>
    </>
  );
};

export default PricesCreate;

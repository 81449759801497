import React, { useState, Fragment, useEffect ,useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import itemtypeimg from "../assets/img/itemtype.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const ItemTypes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [itemTypes, setItemTypes] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [itemTypeId, setItemTypeId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [name, setName] = useState("");
  const [observations, setObservations] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);

  const [search, setSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);

  async function fetchItemTypes() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let itemTypes = await axiosAuth.get("/itemTypes?page=" + pageQuery);

    if (itemTypes.data.error) {
      if (itemTypes.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setItemTypes(itemTypes.data.itemTypes.rows);
    setTotal(itemTypes.data.itemTypes.count);
    setPages(itemTypes.data.itemTypes.pages);

    setCompanies(itemTypes.data.companies.rows);
  }

  useEffect(() => {
    fetchItemTypes();
  }, []);

  useEffect(() => {
    fetchItemTypes();
  }, [location]);

  const editItemType = (itemTypeId) => {
    setAction("edit");
    setItemTypeId(itemTypeId);

    let itemType = itemTypes.filter(
      (itemType) => itemType.id === itemTypeId
    )[0];

    setName(itemType.name);
    setCompanyId(itemType.companyId);
    setObservations(itemType.observations);
    setActive(itemType.active);
    setDeleted(itemType.deleted);
    setSearch(itemType.company.name);
    setOpen(true);
  };

  const clearFields = () => {
    setItemTypeId(0);
    setSearch("");
    setName("");
    setObservations("");
    setActive(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (name === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = itemTypeId;
      let newItemTypesObj = [];

      let updateItemTypeRequest = await axiosAuth.put("/itemTypes", {
        id,
        companyId,
        name,
        observations,
        active,
        deleted,
      });

      if (updateItemTypeRequest.data.error) {
        if (updateItemTypeRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      itemTypes.map((itemType) => {
        if (itemType.id == id) {
          newItemTypesObj.push(updateItemTypeRequest.data);
        } else {
          newItemTypesObj.push(itemType);
        }
        return "";
      });

      setItemTypes(newItemTypesObj);
    } else {
      let createdItemTypeRequest = await axiosAuth.post("/itemTypes", {
        companyId,
        name,
        observations,
        active,
        deleted,
      });

      if (createdItemTypeRequest.data.error) {
        if (createdItemTypeRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newItemTypes = [...itemTypes];
      newItemTypes.push(createdItemTypeRequest.data);
      setItemTypes(newItemTypes);
    }

    setOpen(false);
    clearFields();
  };

  const deleteItemType = async (itemTypeId) => {
    if (window.confirm("Desea eliminar este tipo de articulo ?")) {
      let itemTypeDeleteRequest = await axiosAuth.delete("/itemTypes", {
        params: { id: itemTypeId },
      });

      if (itemTypeDeleteRequest.data.error) {
        if (itemTypeDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newItemTypesObj = [];

      itemTypes.map((itemType) => {
        if (itemType.id !== itemTypeId) {
          newItemTypesObj.push(itemType);
        } else {
          if (user.roleId == 1) {
            itemType.deleted = 1;
            newItemTypesObj.push(itemType);
          }
        }
        return "";
      });

      setItemTypes(newItemTypesObj);
    }
  };

  // Filtrar las empresas según el texto de búsqueda
  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (id, name) => {
    setCompanyId(id);
    setSearch(name); // Mostrar el nombre seleccionado en el input
    setShowDropdown(false); // Cerrar el dropdown
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
            initialFocus={inputRef}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} tipo de
                            articulo
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="ItemTypeId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="ItemTypeId"
                                      type="text"
                                      value={itemTypeId}
                                    />
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <input
                                     ref={inputRef} 
                                      type="text"
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      placeholder="Buscar empresa..."
                                      value={search}
                                      onChange={(e) => setSearch(e.target.value)}
                                      onFocus={() => setShowDropdown(true)} // Abrir el dropdown al enfocar
                                    />
                                    {showDropdown && (
                                      <ul className="absolute z-10 w-full bg-white border rounded shadow-md max-h-40 overflow-y-auto">
                                        {filteredCompanies.length > 0 ? (
                                          filteredCompanies.map((company) => (
                                            <li
                                              key={company.id}
                                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer capitalize"
                                              onClick={() => handleSelect(company.id, company.name)}
                                            >
                                              {company.name}
                                            </li>
                                          ))
                                        ) : (
                                          <li className="py-2 px-3 text-gray-500">No se encontraron empresas</li>
                                        )}
                                      </ul>
                                      )}
                                   {/*  <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="companyId"
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option>Elija una empresa</option>
                                      {companies.length > 0 &&
                                        companies.map((company) => (
                                          <option value={company.id}>
                                            {company.name}
                                          </option>
                                        ))}
                                    </select> */}
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="observations"
                                  >
                                    Observaciones
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="observations"
                                    type="text"
                                    value={observations}
                                    onChange={(e) =>
                                      setObservations(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>

                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Tipos de articulos
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[78px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={itemtypeimg} className="w-16" />
          CREAR TIPO DE ARTICULO
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th class="uppercase">Id</th>
                {user.roleId == 1 && <th class="uppercase">Empresa</th>}
                <th class="uppercase">Nombre</th>
                <th class="uppercase">Observaciones</th>
                <th class="uppercase">Activa</th>
                {user.roleId == 1 && <th class="uppercase">Borrado</th>}
                <th class="uppercase">Fecha Alta</th>
                <th class="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {itemTypes.length > 0 &&
                !isFetching &&
                itemTypes?.map((itemType) => {
                  return (
                    <tr key={itemType.id}>
                      <td>{itemType.id}</td>
                      {user.roleId == 1 && <td>{itemType.company.name}</td>}
                      <td>{itemType.name}</td>
                      <td>{itemType.observations}</td>
                      <td>{itemType.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{itemType.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {itemType.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editItemType(itemType.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteItemType(itemType.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {itemTypes.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="tiposdearticulos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default ItemTypes;

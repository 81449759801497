import React from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";

export const Filtros = ({
    filtroRUC,
    setFiltroRUC,
    filtroEstado,
    setFiltroEstado,
    limpiarFiltros,
}) => {
    return (
        <div className="flex flex-wrap gap-4 mb-4 p-4 bg-gray-50 border rounded-md shadow-sm">
            <Input
                placeholder="Filtrar por RUC"
                value={filtroRUC}
                onChange={(e) => setFiltroRUC(e.target.value)}
                className="max-w-xs border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
            <Select onValueChange={setFiltroEstado} value={filtroEstado}>
                <SelectTrigger className="w-[180px] border-gray-300 focus:ring-2 focus:ring-blue-500 !text-sm">
                    <SelectValue placeholder="Filtrar por Estado" />
                </SelectTrigger>
                <SelectContent className="bg-white shadow-md rounded-md">
                    <SelectItem value="todos">Todos</SelectItem>
                    <SelectItem value="Aprobado">Aprobado</SelectItem>
                    <SelectItem value="Pendiente">Pendiente</SelectItem>
                    <SelectItem value="Rechazado">Rechazado</SelectItem>
                </SelectContent>
            </Select>
            <Button
                onClick={limpiarFiltros}
                className="bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 rounded-md"
            >
                Limpiar Filtros
            </Button>
        </div>
    );
};

import Layout from "../layout";
import * as XLSX from "xlsx";
import xls from "../assets/img/xls.png";
import { axiosAuth } from "../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, Fragment, useEffect, Suspense, useRef } from "react";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import loadingAnimation from "../assets/animations/loading.json";
import { numberWithCommas } from "../utils/constants";
import useViewport from "../utils/useViewport";
import printer from "../assets/img/printer.png";
import searchs from "../assets/img/search.webp";
import ComboBox from "../components/combobox";
import ItemTypes from "./itemtypes";
const ReporteArticulos = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);
  const [isFetching, setIsFetching] = useState(false);
  const [items, setItems] = useState([]);
  const { width } = useViewport();

  const [itemTypes, setItemTypes] = useState([]);
  const [itemTypeId, setItemTypeId] = useState(0);
  //const [searchItemTypes, setSearchItemTypes] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(0);

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(0);

  const [deposits, setDeposits] = useState([]);
  const [depositId, setDepositId] = useState(0);
  const { page, search } = useParams();
  const [buscar, setBuscar] = useState("");
  const [buscarMarca, setBuscarMarca] = useState("");
  const [buscarGrupo, setBuscarGrupo] = useState("");
  const [buscarDeposito, setBuscarDeposito] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownbrand, setShowDropdownBrand] = useState(false);
  const [showDropdowngroup, setShowDropdownGroup] = useState(false);
  const inputRef = useRef(null);
  const inputRefBrand = useRef(null);
  const inputRefGroup = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedBrand, setIsCheckedBrand] = useState(false);
  const [isCheckedGroup, setIsCheckedGroup] = useState(false);
  const [isCheckedDeposit, setIsCheckedDeposit] = useState(false);

  const [filtroBrand, setFiltroBrand] = useState(0);
  const [filtroItemType, setFiltroItemType] = useState(0);
  const [filtroGroup, setFiltroGroup] = useState(0);
  // Estado para manejar los checkboxes e inputs
  /* const [inputsState, setInputsState] = useState({
   checkbox1: { isChecked: false, inputRef: useRef(null) },
   checkbox2: { isChecked: false, inputRef: useRef(null) },
   checkbox3: { isChecked: false, inputRef: useRef(null) },
 }); */

  useEffect(() => {
    fetchItems();
    fetchDeposits();
  }, [location,filtroGroup,filtroBrand,filtroItemType]);
  useEffect(() => {
    fetchItems();
    dispatch(setTitle("Reporte de Stock"));
  }, []);

  async function fetchDeposits() {

    let pageQuery = 1;
    try {
      const response = await axiosAuth.get(`/deposits?page=${pageQuery}`);
      if (!response.data.error) {
      
        setDeposits(response.data.deposits.rows);
      } else {
        if (response.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }
    } catch (error) {
      console.error("Error fetching deposits:", error);
    }
  }
  
  const handleDepositChange = (e) => {
    const value = e.target.value;
      
      setDepositId(value);
    
  };

 

  const exportToExcel = () => {
    const exportData = items.map(
      ({ id, name, price, itemtype, brand, group, stocks }) => ({
        ID: id,
        Nombre: name,
        Precio: price,
        Tipo: itemtype?.name,
        Marca: brand?.name,
        Grupo: group?.name,
        Stock: stocks?.[0]?.existence ?? "N/A",
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LISTADO DE STOCK");
    XLSX.writeFile(
      workbook,
      `LISTA DE STOCK-${new Date().toLocaleDateString()}.xlsx`
    );
  };

  async function fetchItems() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let items = await axiosAuth.get(
      "/items?page=" + pageQuery + "&search=" + search + "&boole=2&brand="+filtroBrand+"&group="+filtroGroup+"&itemType="+filtroItemType
    );

    if (items.data.error) {
      if (items.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setItems(items.data.items.rows);
    setItemTypes(items.data.itemtypes);
    setBrands(items.data.brands);
    setGroups(items.data.groups);

  }

  const consultar=()=>{
    fetchItems();
  }
  /* 
    const searchItemtypes = async (search) => {
      let pageQuery = page == undefined ? 1 : page;
      const searchItemtypeRequest = await axiosAuth.get(
        "/itemTypes?page=" + pageQuery + "&search=" + search
      );
      setItemTypes(searchItemtypeRequest.data.itemTypes.rows);
    }; */

  // Filtrar los tipos de articulos según el texto de búsqueda
  const filteredItemTypes = itemTypes.filter((it) =>
    it.name.toLowerCase().includes(buscar.toLowerCase())
  );
  // Filtrar las marcas según el texto de búsqueda
  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(buscarMarca.toLowerCase())
  );
  // Filtrar las grupos según el texto de búsqueda
  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(buscarGrupo.toLowerCase())
  );

  const handleSelect = (id, name, valor) => {
    switch (valor) {
      case "Brand":
        setBrandId(id);
        setBuscarMarca(name);
        setShowDropdownBrand(false);
        setFiltroBrand(id);
        break;
      case "Itemtype":
        setItemTypeId(id);
        setBuscar(name); // Mostrar el nombre seleccionado en el input
        setShowDropdown(false); // Cerrar el dropdown
        setFiltroItemType(id);
        break;

      case "Group":
        setGroupId(id);
        setBuscarGrupo(name);
        setShowDropdownGroup(false);
        setFiltroGroup(id); 
        break;

    }

  };

  // Función para manejar el cambio del checkbox
  const handleCheckboxChange = (check) => {
    switch (check) {
      case "chkbrand":
        setIsCheckedBrand(!isCheckedBrand);
        setBrandId(0);
        setBuscarMarca("");
        setShowDropdownBrand(false);
        setFiltroBrand(0);
       // Cambiar el estado de isChecked
        break;
      case "chkitemtype":
        setIsChecked(!isChecked);
        setShowDropdown(false);
        setItemTypeId(0);
        setBuscar(""); 
        setFiltroItemType(0);

       // Cambiar el estado de isChecked
        break;
      case "chkgroup":
        setIsCheckedGroup(!isCheckedGroup);
        setGroupId(0);
        setBuscarGrupo("");
        setShowDropdownGroup(false);
        setFiltroGroup(0);
        // Cambiar el estado de isChecked
        break;
        case "chkdeposit":
        setIsCheckedDeposit(!isCheckedDeposit);
        setDepositId(0);
       // Cambiar el estado de isChecked
        break;
      default:
        
    }

  };

  // Función para habilitar el input y darle foco cuando el checkbox está seleccionado
  const handleFocusInput = () => {
    if (isChecked && inputRef.current) {
      inputRef.current.focus(); // Dar foco al input
    } else if (isCheckedBrand && inputRefBrand.current) {
      inputRefBrand.current.focus();
    } else if (isCheckedGroup && inputRefGroup.current) {
      inputRefGroup.current.focus();
    }
  };

  // Llamamos a handleFocusInput en cada renderizado cuando isChecked cambia
  React.useEffect(() => {
    handleFocusInput();
  }, [isChecked, isCheckedBrand,isCheckedGroup,isCheckedDeposit]);



  // Función para manejar el cambio de cualquier checkbox
  /*   const handleCheckboxChange = (checkboxKey) => {
      setInputsState((prevState) => {
        const newState = { ...prevState };
        const isChecked = !newState[checkboxKey].isChecked;
        newState[checkboxKey].isChecked = isChecked;
  
        // Si el checkbox está seleccionado, damos foco al input
        if (isChecked && newState[checkboxKey].inputRef.current) {
          newState[checkboxKey].inputRef.current.focus();
        }
  
        return newState;
      });
    }; */



  return (
    <>
      <Layout>
        <div
          class="w-full top-[-35px] flex flex-col sm:flex-row pb-1 sm:h-[125px] relative bg-white h-[250px] "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[22%] w-[50%]" style={{ borderRight: "1px solid lightgray" }}>
            <h1 className="ml-5 mt-7 secondary-color title font-family-thin">
              Reporte de Stock
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>

          <div className="container mx-auto p-4 mt-[-5px] " >
            <div
              className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 ml-[-10px]" 

            >
              <div  className="w-[370px]">
                <div className=" sm:flex sm:flex-row flex flex-col sm:items-center sm:justify-end ">
                  <div className="w-44 sm:justify-items-end justify-items-start" >

                    <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px] sm:mt-5 ml-5" >
                      <input
                        id="salesMarcasFilter"
                        name="salesMarcasFilter"
                        type="checkbox"
                        className="mr-2"
                        checked={isCheckedBrand}
                        onChange={() => handleCheckboxChange("chkbrand")}
                      />
                      Marcas:
                    </label>
                  </div>
                  <div className="sm:justify-items-center justify-items-start" >
                    <input
                      ref={inputRefBrand}// Referencia al input
                      type="text"
                      className="input-bg-color appearance-none border rounded sm:w-[230px] w-[250px] sm:mt-5 block 
                        ml-5
                       py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 
                       capitalize"
                      placeholder="Seleccione Marca"
                      value={buscarMarca}
                      onChange={(e) => setBuscarMarca(e.target.value)}
                      onFocus={() => setShowDropdownBrand(true)} // Abrir el dropdown al enfocar
                      disabled={!isCheckedBrand}
                    />
                    {showDropdownbrand && (
                      <ul className="absolute z-10 w-[400px] bg-white border rounded shadow-md max-h-40 overflow-y-auto">
                        {filteredBrands.length > 0 ? (
                          filteredBrands.map((i) => (
                            <li
                              key={i.id}
                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer capitalize"
                              onClick={() => handleSelect(i.id, i.name, "Brand")}
                            >
                              {i.name}
                            </li>
                          ))
                        ) : (
                          <li className="py-2 px-3 text-gray-500">No se encontraron Marcas</li>
                        )}
                      </ul>
                    )}
              
                  </div>
                </div>
                <div className=" sm:flex sm:flex-row flex flex-col sm:items-center justify-end " >
                  <div class="sm:w-52 sm:justify-items-end" >
                    <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5 mt-1 sm:ml-0 ml-5">
                      <input
                        id="ItemTypeFilter"
                        name="ItemTypeFilter"
                        type="checkbox"
                        className="mr-2"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange("chkitemtype")}
                      />
                      TIPOS DE ARTICULOS:
                    </label>
                  </div>
                  <div class="basis-1/2">
                    <input
                      ref={inputRef}// Referencia al input
                      type="text"
                      className="input-bg-color appearance-none border rounded sm:w-[230px] w-[250px] ml-5
                       py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 
                       capitalize sm:mt-5 inline-block leading-[10px]
                        "
                      placeholder="Seleccione Tipo de Articulo"
                      value={buscar}
                      onChange={(e) => setBuscar(e.target.value)}
                      onFocus={() => setShowDropdown(true)} // Abrir el dropdown al enfocar

                      disabled={!isChecked}
                    />
                    {showDropdown && (
                      <ul className="absolute z-10 w-full bg-white border rounded shadow-md max-h-40 overflow-y-auto">
                        {filteredItemTypes.length > 0 ? (
                          filteredItemTypes.map((company) => (
                            <li
                              key={company.id}
                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer capitalize"
                              onClick={() => handleSelect(company.id, company.name, "Itemtype")}
                            >
                              {company.name}
                            </li>
                          ))
                        ) : (
                          <li className="py-2 px-3 text-gray-500">No se encontraron empresas</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-[340px] mt-[-8px] sm:mt-0"  >
                <div className="sm:flex sm:flex-row flex flex-col sm:items-end  ">
                  <div class="w-32 sm:justify-items-end">
                    <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] ml-5 sm:ml-0 ">
                    <input
                        id="ItemTypeFilter"
                        name="ItemTypeFilter"
                        type="checkbox"
                        className="mr-2"
                        checked={isCheckedGroup}
                        onChange={() => handleCheckboxChange("chkgroup")}
                      />
                      GRUPOS:
                    </label>
                  </div>
                  <div class="sm:mt-6">
                  <input
                      ref={inputRefGroup}// Referencia al input
                      type="text"
                      className="input-bg-color appearance-none border rounded w-[250px] sm:w-[230px]   ml-5 sm:ml-0
                       py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 
                       capitalize"
                      placeholder="Seleccione Grupo"
                      value={buscarGrupo}
                      onChange={(e) => setBuscarGrupo(e.target.value)}
                      onFocus={() => setShowDropdownGroup(true)} // Abrir el dropdown al enfocar
                      disabled={!isCheckedGroup}
                    />
                    {showDropdowngroup && (
                      <ul className="absolute z-10 w-full bg-white border rounded shadow-md max-h-40 overflow-y-auto">
                        {filteredGroups.length > 0 ? (
                          filteredGroups.map((i) => (
                            <li
                              key={i.id}
                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer capitalize"
                              onClick={() => handleSelect(i.id, i.name, "Group")}
                            >
                              {i.name}
                            </li>
                          ))
                        ) : (
                          <li className="py-2 px-3 text-gray-500">No se encontraron Grupos</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="sm:flex sm:flex-row flex flex-col sm:items-end  ">
                  <div className="w-32 sm:justify-items-end">
                    <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px] sm:mt-5 mt-1 sm:ml-0 ml-5">
                    <input
                        id="ItemTypeFilter"
                        name="ItemTypeFilter"
                        type="checkbox"
                        className="mr-2"
                        checked={isCheckedDeposit}
                        onChange={() => handleCheckboxChange("chkdeposit")}
                      />
                      Deposito:
                    </label>
                  </div>
                  <div>
                    <select
                      className="input-bg-color appearance-none border w-[250px]  rounded  inline-block  sm:w-[230px] ml-5 sm:ml-0
                                       py-2 px-3 bg-gray-700 focus:outline-none focus:shadow-outline leading-[10px] sm:mt-5"

                      value={depositId}
                      onChange={handleDepositChange}
                      disabled={!isCheckedDeposit}
                    >
                      <option value={0}>Seleccione Deposito</option>
                      {deposits.map((deposit) => (
                        <option key={deposit.id} value={deposit.id}>
                          {deposit.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <div  
                  className="bg-white text-white p-2 sm:mt-1  sm:ml-[-5px] mt-[-10px]
                   rounded-md  sm:flex sm:flex-col w-full sm:w-[230px]  items-start            
                            "

                >
                  <div className="grid grid-cols-3 md:grid-cols-2 gap-0 mt-0 ml-3 sm:ml-0" >
                    <div>
                    <div className="sm:flex sm:flex-col items-center sm:mt-[-12px]">
                    <button
                      type="button"
                      className="inline-flex flex-row  mr-1 justify-center items-center  h-[35px] w-[100px]
                                           rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                           hover:bg-gray-400 sm:mt-5"
                                           onClick={() => consultar()}
                    >
                      <img src={searchs} className="w-6" />
                      CONSULTAR
                    </button>
                  </div>
                    </div>
                    <div>
                    <div className="sm:flex sm:flex-col items-center sm:mt-[-12px]">
                    <button
                      type="button"
                      onClick={exportToExcel}
                      className="inline-flex flex-row  mr-1 justify-center items-center  h-[35px] w-[100px]
                                             rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                             hover:bg-gray-400 sm:mt-5"
                    >
                      <img src={xls} className="w-6" />
                      EXPORTAR
                    </button>
                  </div>
                    </div>
                    <div>
                    <div className="sm:flex sm:flex-col items-center sm:mt-[-12px]">
                    <button
                      type="button"
                      className="inline-flex flex-row  mr-1 justify-center items-center  h-[35px] w-[100px]
                                             rounded ternary-bg-color p-4 text-xs text-gray-900 
                                             shadow-sm ring-1 ring-inset ring-gray-300
                                             hover:bg-gray-400 sm:mt-5"
                    >
                      <img src={printer} className="w-6" />
                      IMPRIMIR
                    </button>
                  </div>
                    </div>

                  </div>
                 
                 
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[95%] p-6 mx-auto overflow-auto sm:max-h-[70vh] max-h-[70vh] sm:mt-[-25px] 
                   mt-[-33px]" >
          <table className="w-96 table-auto mx-auto w-full text-[10px] support-table relative">
            <thead className="bg-gray-600 !text-white relative md:fixed sm:mt-[-25px]   w-[88.8%]">
              {width > 1024 ? (
                <tr className="bg-gray-500 h-5 w-96">
                  <th className="uppercase w-[60px] !text-white text-center"  >Id</th>
                  <th className="uppercase w-[300px]  !text-white text-center "  >
                    Nombre
                  </th>
                  <th className="uppercase w-[180px] co  !text-white text-center" >
                    Cod. de Barras
                  </th>
                  <th className="uppercase !text-white text-right pr-[20px] w-[80px] " >
                    Precio
                  </th>
                  {/*    {user.roleId == 1 && (
                                        <th className="uppercase !text-white text-left">Empresa</th>
                                    )} */}
                  <th className="uppercase  !text-white text-center pr-[20px] w-[190px]" >
                    Tipo
                  </th>
                  <th className="uppercase w-[150px]  !text-white text-left " >Marca</th>
                  <th className="uppercase w-[140px]  !text-white text-center" >Grupo</th>
                  <th className="uppercase w-[150px]  !text-white text-right" >Stock</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {items.length > 0 &&
                !isFetching &&
                items?.map((item) => {
                  if (width > 1024) {
                    return (
                      <tr key={item.id}>
                        <td >{item.id}</td>
                        <td className="text-left whitespace-nowrap">
                          {item.name}
                        </td>
                        <td className="text-right whitespace-nowrap ">
                          {item.barcode}
                        </td>
                        <td className="text-right whitespace-nowrap pr-[20px] ">
                          {" "}
                          {numberWithCommas(item.price)}
                        </td>

                        {/* {user.roleId == 1 && (
                                                    <td className="text-left whitespace-nowrap ">
                                                        {item.company.name}
                                                    </td>
                                                )} */}
                        <td className="text-center">{item?.itemtype?.name}</td>
                        <td className="text-left whitespace-nowrap ">
                          {item?.brand?.name}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {item?.group?.name}
                        </td>

                        <td>{item?.stocks?.[0]?.existence ?? "N/A"}</td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr >
                        <label className="font-bold">ID: </label>
                        {item.id}
                        <br />
                        <label className="font-bold">DESCRIPCION: </label>
                        {item.name}
                        <br />
                        <label className="font-bold">COD. DE BARRAS: </label>
                        {item.barcode}
                        <br />
                        <label className="font-bold">PRECIO: </label>
                        {item.price}
                        <br />
                        <label className="font-bold">STOCK: </label>
                        {item?.stocks?.[0]?.existence ?? "N/A"}
                      </tr>
                    );
                  }
                })}
              {items.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={21} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
            { }
          </table>
        </div>
      </Layout>
    </>
  );
};

export default ReporteArticulos;

"use client"

import { useState, useEffect } from "react"
import Layout from "../layout";
import { PlusCircle, Bell, Calendar, DollarSign, Phone, Trash2, MessageCircle } from "lucide-react"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { Textarea } from '../components/ui/textarea';
import { Checkbox } from "../components/ui/checkbox"
import { Card, CardContent } from "../components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog"
import { Toast, ToastProvider, ToastViewport } from "../components/ui/toast"
import { useToast } from "../components/ui/use-toast"
import { axiosAuth } from "../utils/axios"

type Message = {
    id?: string;
    content: string;
    date: string;
    userId: string;
};

type Lead = {
    messages: Message[];  // Specify the Message type here
    id: string
    name: string
    company: string
    email: string
    phone: string
    stage: "contacto" | "interesado" | "demo" | "negociacion" | "cierre"
    demoDate?: string
    recontact?: boolean
}

function LeadCard({
    lead,
    onUpdate,
    onDelete,
    onAddMessage
}: {
    lead: Lead;
    onUpdate: (id: string, updates: Partial<Lead>) => void;
    onDelete: (id: string) => void;
    onAddMessage: (leadId: string, messageData: { content: string, userId: string, leadId: string }) => void;
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedLead, setEditedLead] = useState<Lead>(lead);
    const [newMessage, setNewMessage] = useState('');

    const handleSaveChanges = async () => {
        const updates: Partial<Lead> = {};

        // Existing update logic remains the same
        if (lead.name !== editedLead.name) updates.name = editedLead.name;
        if (lead.company !== editedLead.company) updates.company = editedLead.company;
        if (lead.email !== editedLead.email) updates.email = editedLead.email;
        if (lead.phone !== editedLead.phone) updates.phone = editedLead.phone;

        if (lead.stage === "demo" && lead.demoDate !== editedLead.demoDate) {
            updates.demoDate = editedLead.demoDate;
        }

        if ((lead.stage === "contacto" || lead.stage === "interesado")
            && lead.recontact !== editedLead.recontact) {
            updates.recontact = editedLead.recontact;
        }

        if (Object.keys(updates).length > 0) {
            try {
                await onUpdate(lead.id, updates);
                setIsEditing(false);
            } catch (error) {
                console.error('Error updating lead:', error);
            }
        } else {
            setIsEditing(false);
        }
    };

    const handleAddMessage = async () => {
        if (newMessage.trim()) {
            try {
                // Assuming you'll pass the current user's ID
                await onAddMessage(lead.id, {
                    content: newMessage,
                    userId: '1', 
                    leadId: lead.id,
                });
                setNewMessage('');
            } catch (error) {
                console.error('Error adding message:', error);
            }
        }
    };

    const handleDelete = async () => {
        try {
            await axiosAuth.delete(`/leads/${lead.id}`);
            onDelete(lead.id);
        } catch (error) {
            console.error('Error deleting lead:', error);
        }
    };

    return (
        <Dialog open={isEditing} onOpenChange={setIsEditing}>
            <Card className="mb-2 relative">
                <Button
                    variant="ghost"
                    size="icon"
                    className="absolute top-2 right-2"
                    onClick={handleDelete}
                >
                    <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
                <DialogTrigger asChild>
                    <CardContent
                        className="p-4 cursor-pointer"
                        onClick={() => {
                            setEditedLead(lead)
                            setIsEditing(true)
                        }}
                    >
                        <div>
                            <div className="font-medium">{lead.name}</div>
                            <div className="text-sm text-gray-600">{lead.company}</div>
                            <div className="text-sm text-gray-500">{lead.email}</div>
                            

                            <div className="text-sm text-gray-500 flex items-center">
                                <Phone className="mr-2 h-4 w-4" />
                                {lead.phone}
                            </div>
                            {lead.messages && lead.messages.length > 0 && (
                                <div className="text-sm text-gray-500 mt-2">
                                    Último mensaje: {lead.messages[lead.messages.length - 1].content} <br />
                                    Fecha: {new Date(lead.messages[lead.messages.length - 1].date).toLocaleDateString("es-ES", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                    })}
                                </div>
                            )}
                        </div>
                    </CardContent>
                </DialogTrigger>
            </Card>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar Lead</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Nombre *
                        </Label>
                        <Input
                            id="name"
                            value={editedLead.name}
                            onChange={(e) => setEditedLead({ ...editedLead, name: e.target.value })}
                            className="col-span-3"
                            placeholder="Nombre del contacto"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="company" className="text-right">
                            Empresa
                        </Label>
                        <Input
                            id="company"
                            value={editedLead.company}
                            onChange={(e) => setEditedLead({ ...editedLead, company: e.target.value })}
                            className="col-span-3"
                            placeholder="Nombre de la empresa"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="email" className="text-right">
                            Email
                        </Label>
                        <Input
                            id="email"
                            type="email"
                            value={editedLead.email}
                            onChange={(e) => setEditedLead({ ...editedLead, email: e.target.value })}
                            className="col-span-3"
                            placeholder="ejemplo@empresa.com"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="phone" className="text-right">
                            Teléfono
                        </Label>
                        <Input
                            id="phone"
                            type="tel"
                            value={editedLead.phone}
                            onChange={(e) => setEditedLead({ ...editedLead, phone: e.target.value })}
                            className="col-span-3"
                            placeholder="Número de contacto"
                        />
                    </div>
                    {lead.stage === "demo" && (
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="demoDate" className="text-right">
                                Fecha de Demo
                            </Label>
                            <Input
                                id="demoDate"
                                type="datetime-local"
                                value={editedLead.demoDate || ""}
                                onChange={(e) => setEditedLead({ ...editedLead, demoDate: e.target.value })}
                                className="col-span-3"
                            />
                        </div>
                    )}
                    {(lead.stage === "contacto" || lead.stage === "interesado") && (
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label className="text-right">Recontacto</Label>
                            <div className="col-span-3 flex items-center">
                                <Checkbox
                                    id={`recontact-${lead.id}`}
                                    checked={editedLead.recontact}
                                    onCheckedChange={(checked) => setEditedLead({ ...editedLead, recontact: checked as boolean })}
                                />
                                <Label htmlFor={`recontact-${lead.id}`} className="ml-2">
                                    Volver a contactar
                                </Label>
                            </div>
                        </div>
                    )}
                    <div className="border-t pt-4">
                        <h3 className="text-lg font-semibold mb-3 flex items-center">
                            <MessageCircle className="mr-2 h-5 w-5" /> Mensajes
                        </h3>

                        {/* Message List */}
                        <div className="max-h-60 overflow-y-auto mb-4">
                            {lead.messages && lead.messages.length > 0 ? (
                                lead.messages.map((message, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-100 p-3 rounded-lg mb-2"
                                    >
                                        <div className="text-sm font-medium">
                                            {new Date(message.date).toLocaleString("es-ES", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit"
                                            })}
                                        </div>
                                        <div className="text-gray-700">{message.content}</div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500 text-center">No hay mensajes</p>
                            )}
                        </div>

                        {/* New Message Input */}
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="newMessage" className="text-right">Nuevo Mensaje</Label>
                            <Textarea
                                id="newMessage"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                className="col-span-3"
                                placeholder="Escribe un nuevo mensaje..."
                            />
                        </div>
                        <div className="flex justify-end mt-2">
                            <Button
                                onClick={handleAddMessage}
                                disabled={!newMessage.trim()}
                            >
                                Agregar Mensaje
                            </Button>
                        </div>
                    </div>
                </div>
    
            <div className="flex justify-end space-x-2">
                <Button
                    variant="outline"
                    onClick={() => setIsEditing(false)}
                >
                    Cancelar
                </Button>
                <Button onClick={handleSaveChanges}>
                    Guardar Cambios
                </Button>
            </div>
        </DialogContent>
        </Dialog >
    )
}
function LeadColumn({
    stage,
    leads,
    onDragOver,
    onDrop,
    onUpdateLead,
    onDeleteLead,
    onAddMessage, // Add this
}: {
    stage: Lead["stage"]
    leads: Lead[]
    onDragOver: (e: React.DragEvent) => void
    onDrop: (e: React.DragEvent, stage: Lead["stage"]) => void
    onUpdateLead: (id: string, updates: Partial<Lead>) => void
    onDeleteLead: (id: string) => void
    onAddMessage: (leadId: string, messageData: { content: string, userId: string }) => void // Add this type
}) {
    return (
        <div
            className="flex-1 min-w-[250px] bg-gray-100 p-4 rounded-lg"
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, stage)}
        >
            <h2 className="font-semibold mb-2 capitalize">{stage}</h2>
            {leads
                .filter((lead) => lead.stage === stage)
                .map((lead) => (
                    <div key={lead.id} draggable onDragStart={(e) => e.dataTransfer.setData("text/plain", lead.id)}>
                        <LeadCard
                            lead={lead}
                            onUpdate={onUpdateLead}
                            onDelete={onDeleteLead}
                            onAddMessage={onAddMessage} // Add this line
                        />
                    </div>
                ))}
        </div>
    )
}
function AddLeadDialog({ onAddLead }: { onAddLead: (lead: Omit<Lead, "id" | "stage">) => void }) {
    const [isOpen, setIsOpen] = useState(false)
    const [newLead, setNewLead] = useState<Omit<Lead, "id" | "stage">>({
        name: "",
        company: "",
        email: "",
        phone: "",
        messages: []
    })
    const { toast } = useToast()

    const handleAddLead = async () => {
        debugger;
        // Basic validation
        if (!newLead.name.trim()) {
            toast({
                title: "Error",
                description: "El nombre es obligatorio",
                variant: "destructive"
            });
            return;
        }



        try {
            await onAddLead(newLead);
            setNewLead({ name: "", company: "", email: "", phone: "", messages: [] });
            setIsOpen(false);
        } catch (error) {
            toast({
                title: "Error",
                description: "No se pudo agregar el lead",
                variant: "destructive"
            });
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button>
                    <PlusCircle className="mr-2 h-4 w-4" />
                    Agregar Lead
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Agregar Nuevo Lead</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Nombre *
                        </Label>
                        <Input
                            id="name"
                            value={newLead.name}
                            onChange={(e) => setNewLead({ ...newLead, name: e.target.value })}
                            className="col-span-3"
                            placeholder="Nombre del contacto"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="company" className="text-right">
                            Empresa
                        </Label>
                        <Input
                            id="company"
                            value={newLead.company}
                            onChange={(e) => setNewLead({ ...newLead, company: e.target.value })}
                            className="col-span-3"
                            placeholder="Nombre de la empresa"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="email" className="text-right">
                            Email
                        </Label>
                        <Input
                            id="email"
                            type="email"
                            value={newLead.email}
                            onChange={(e) => setNewLead({ ...newLead, email: e.target.value })}
                            className="col-span-3"
                            placeholder="ejemplo@empresa.com"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="phone" className="text-right">
                            Teléfono
                        </Label>
                        <Input
                            id="phone"
                            type="tel"
                            value={newLead.phone}
                            onChange={(e) => setNewLead({ ...newLead, phone: e.target.value })}
                            className="col-span-3"
                            placeholder="Número de contacto"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2">
                    <Button
                        variant="outline"
                        onClick={() => setIsOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleAddLead}>
                        Agregar Lead
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

function DemoNotification({ count }: { count: number }) {
    return (
        <div className="flex items-center">
            <Bell className="mr-2 h-5 w-5" />
            <span>Demos hoy: {count}</span>
        </div>
    )
}

export default function LeadManagement() {
    const [leads, setLeads] = useState<Lead[]>([]);
    const [todayDemos, setTodayDemos] = useState<Lead[]>([]);
    const { toast } = useToast();

    const stages: Lead["stage"][] = ["contacto", "interesado", "demo", "negociacion", "cierre"];

    const handleDeleteLead = async (id: string) => {
        try {
            setLeads(leads.filter(lead => lead.id !== id));
            toast({
                title: "Lead eliminado",
                description: "El lead ha sido eliminado exitosamente",
            });
        } catch (error) {
            console.error('Error deleting lead:', error);
            toast({
                title: "Error",
                description: "No se pudo eliminar el lead",
                variant: "destructive",
            });
        }
    };

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const response = await axiosAuth.get('/leads');
                setLeads(response.data);
            } catch (error) {
                console.error('Error fetching leads:', error);
                toast({
                    title: "Error",
                    description: "No se pudieron cargar los leads",
                    variant: "destructive",
                });
            }
        };

        fetchLeads();
    }, []);

    const refetchLeads = async () => {
        const response = await axiosAuth.get('/leads');
        setLeads(response.data);
    };

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        const demosToday = leads.filter((lead) => lead.demoDate?.startsWith(today));
        setTodayDemos(demosToday);

        if (demosToday.length > 0) {
            toast({
                title: "Demos programadas",
                description: `Tienes ${demosToday.length} demo(s) programada(s) para hoy.`,
            });
        }
    }, [leads, toast]);

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = async (e: React.DragEvent, targetStage: Lead["stage"]) => {
        e.preventDefault();
        const leadId = e.dataTransfer.getData("text");

        try {
            await axiosAuth.put(`/leads/${leadId}`, { stage: targetStage });
            await refetchLeads();
        } catch (error) {
            console.error('Error updating lead stage:', error);
            toast({
                title: "Error",
                description: "No se pudo actualizar el estado del lead",
                variant: "destructive",
            });
        }
    };

    const handleAddLead = async (newLead: Omit<Lead, "id" | "stage">) => {
        try {
            const response = await axiosAuth.post('/leads', {
                ...newLead,
                stage: 'contacto'
            });

            setLeads([...leads, response.data]);

            toast({
                title: "Lead agregado",
                description: "El lead ha sido creado exitosamente",
            });
        } catch (error) {
            console.error('Error creating lead:', error);
            toast({
                title: "Error",
                description: "No se pudo crear el lead",
                variant: "destructive",
            });
        }
    };

    const handleUpdateLead = async (id: string, updates: Partial<Lead>) => {
        try {
            const currentLead = leads.find(lead => lead.id === id);
            const response = await axiosAuth.put(`/leads/${id}`, {
                ...currentLead,
                ...updates
            });

            setLeads(leads.map((lead) =>
                lead.id === id ? response.data : lead
            ));
        } catch (error) {
            console.error('Error updating lead:', error);
            toast({
                title: "Error",
                description: "No se pudo actualizar el lead",
                variant: "destructive",
            });
        }
    };
    const handleAddMessage = async (leadId: string, messageData: { content: string, userId: string }) => {
        try {
            const response = await axiosAuth.post(`/leads/${leadId}/messages`, messageData);
            await refetchLeads();
            
            toast({
                title: "Mensaje agregado",
                description: "El mensaje ha sido enviado exitosamente",
            });
        } catch (error) {
            console.error('Error adding message:', error);
            toast({
                title: "Error",
                description: "No se pudo agregar el mensaje",
                variant: "destructive",
            });
        }
    };

    return (
        <Layout>
            <ToastProvider>
                <div className="container mx-auto p-4">
                    <h1 className="text-2xl font-bold mb-4">Gestión de Leads</h1>
                    <div className="flex justify-between items-center mb-4">
                        <AddLeadDialog onAddLead={handleAddLead} />
                        <DemoNotification count={todayDemos.length} />
                    </div>
                    <div className="flex space-x-4 overflow-x-auto pb-4">
                        {stages.map((stage) => (
                              <LeadColumn
                              key={stage}
                              stage={stage}
                              leads={leads}
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                              onUpdateLead={handleUpdateLead}
                              onDeleteLead={handleDeleteLead}
                              onAddMessage={handleAddMessage} // Add this line
                          />
                        ))}
                    </div>
                </div>
                <ToastViewport />
            </ToastProvider>
        </Layout>
    );

}


'use client'

import React, { useState } from 'react'
import Layout from "../layout";
import { Filtros } from './componentes/Filtros.js'
import { TablaFacturas } from './componentes/TablaFacturas.js'
import { facturas } from './datos/facturas'

const FacturacionElectronica = () => {
  const [filtroRUC, setFiltroRUC] = useState('')
  const [filtroEstado, setFiltroEstado] = useState('')

  const facturasFiltradas = facturas.filter(factura => 
    factura.ruc.includes(filtroRUC) &&
    (filtroEstado === '' || factura.estado === filtroEstado)
  )

  const limpiarFiltros = () => {
    setFiltroRUC('')
    setFiltroEstado('')
  }
  const handleReenviar = (factura) => {
    console.log('Reenviar documento:', factura);
  };
  
  const handleVer = (factura) => {
    console.log('Ver documento:', factura);
  };

  return (
    <>
      <Layout>

    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Facturación Electrónica - Paraguay</h1>
      
      <Filtros 
        filtroRUC={filtroRUC}
        setFiltroRUC={setFiltroRUC}
        filtroEstado={filtroEstado}
        setFiltroEstado={setFiltroEstado}
        limpiarFiltros={limpiarFiltros}
      />

<TablaFacturas facturas={facturas} onReenviar={handleReenviar} onVer={handleVer} />;
    </div>
    </Layout>
    </>
  );
}

export default FacturacionElectronica

